import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
//import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Row, Col, Card, CardBody, FormGroup, Button} from "reactstrap";
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {MetaTags} from 'react-meta-tags';
//import Loader from "../../components/Loader";
// action
import { registerUser } from "../../store/actions";
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';

// Redux
import { connect } from "react-redux";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
        countries: [],
        states: [],
        cities: [],
        user:[],
        loading:true
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    i18next.changeLanguage(localStorage.getItem('lang'));
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.users === 0){
        window.history.back();
      }
    })
    document.getElementsByClassName("role_chk_div")[0].style.display = "none";
    
    fetch(config.API+'get_coutries', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
        countries:findresponse,
        loading:false
      })
      
    })
    }

    changeCountry = (event) => {
        var cid= event.target.value;
        fetch(config.API+'get_states/'+cid, {
          headers: {  
            'Nexion_API_Key': config.API_KEY
          }
        }).then((Response)=>Response.json()).then((findresponse)=>{
            //console.log(findresponse)
            this.setState({
                states:findresponse,
            })
            
            })
      };

      changeRole = (event) => {
        var role= event.target.value;
        //console.log();
        //alert(cid);
        if(role === "custom"){
          document.getElementsByClassName("role_chk_div")[0].style.display = "block";
        }else{
          document.getElementsByClassName("role_chk_div")[0].style.display = "none";
        }
      };

      // changeState = (event) => {
      //   var sid= event.target.value;
      //   fetch(config.API+'get_cities/'+sid, {
      //     headers: {  
      //       'Nexion_API_Key': config.API_KEY
      //     }
      //   }).then((Response)=>Response.json()).then((findresponse)=>{
      //       //console.log(findresponse)
      //       this.setState({
      //           cities:findresponse,
      //       })
            
      //       })
      // };

       // handleValidSubmit
  handleValidSubmit(event, values) {
    //console.log(values);
    this.props.registerUser(values);
  }


  render() {
    const { t } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
         <MetaTags>
      <title>Add User | Technomotor Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">{t('adduserspage.add_user')}</h4>
                {/* {this.props.loading ? <Loader /> : null} */}
              </div>
            </Col>
          </Row>
          <AvForm
          
          onValidSubmit={this.handleValidSubmit}
        >
          
          <Card>
            <CardBody>
          <Row>
            <Col lg={6}>

                    <AvField
                      name="username"
                      label= {t('adduserspage.username')}
                      placeholder={t('adduserspage.username_place')}
                      type="text"
                      errorMessage={t('adduserspage.username_err')}
                      validate={{ required: { value: true } }}
                    />
                    <label>{t('adduserspage.password')}</label>
                    <AvField
                      name="password"
                      type="password"
                      placeholder={t('adduserspage.password_place')}
                      errorMessage={t('adduserspage.password_err')}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="email"
                      label={t('adduserspage.email')}
                      placeholder={t('adduserspage.email_place')}
                      type="email"
                      errorMessage={t('adduserspage.email_err')}
                      validate={{
                        required: { value: true },
                        email: { value: true }
                      }}
                    />
                    <AvField type="select" name="dis" label={t('adduserspage.disable')}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                    </AvField>

              <AvField type="select" onChange={this.changeRole} value={value} name="role" label={t('adduserspage.role')} required errorMessage={t('adduserspage.role_err')}>
              <option value="">{t('adduserspage.select')}</option>
              <option value="AC Operator">AC Operator</option>
              <option value="Assistance">Assistance</option>
              <option value="Diagnosis Manager">Diagnosis Manager</option>
              <option value="Diagnosis Developer">Diagnosis Developer</option>
              <option value="Diagnosis Tester">Diagnosis Tester</option>
              <option value="Commercial">Commercial</option>
              </AvField>

              <div className="role_chk_div">
                <AvCheckboxGroup inline name="roles_chk">
                  <AvCheckbox label="Home Page Statistics" value="homestats" />
                  <AvCheckbox label="Users" value="users" />
                  <AvCheckbox label="Diagnosis" value="diagnosis" />
                  <AvCheckbox label="Versions" value="versions" />
                  <AvCheckbox label="Diagnosis Test Records" value="diag_test" />
                  <AvCheckbox label="Version Test Records" value="ver_test" />
                </AvCheckboxGroup>
              </div>

              <AvField type="select" name="soc" label={t('adduserspage.soc')} required errorMessage={t('adduserspage.soc_err')}>
              <option value="">{t('adduserspage.select')}</option>
              <option value="TM">Technomotor</option>
              
              </AvField>

              {/* <AvField type="select" name="fam" label={t('adduserspage.fam')} required errorMessage={t('adduserspage.fam_err')}>
              <option value="">{t('adduserspage.select')}</option>
              <option value="Europe">Europe</option>
              <option value="Argentine">Argentine</option>
              <option value="Brazil">Brazil</option>
              </AvField> */}

              <AvField type="select" multiple="multiple" name="lang" label={t('adduserspage.ling')} required errorMessage={t('adduserspage.ling_err')}>
              {/* <option value="">{t('adduserspage.select')}</option> */}
								<option value="alb">Albanian</option>
								<option value="ara">Arabic</option>
								<option value="bra">Brazilian</option>
								<option value="bul">Bulgarian</option>
								<option value="cec">Czech</option>
								<option value="cin">Chinese</option>
								<option value="cro">Croatian</option>
								<option value="fin">Finnish</option>
								<option value="fra">French</option>
								<option value="gre">Greek</option>
								<option value="ing">English</option>
								<option value="ita">Italian</option>
								<option value="jap">Japanese</option>
								<option value="nor">Norwegian</option>
								<option value="ola">Dutch</option>
								<option value="pol">Polish</option>
								<option value="por">Portuguese</option>
								<option value="rum">Romanian</option>
								<option value="rus">Russian</option>
								<option value="ser">Serbian</option>
								<option value="spa">Spanish</option>
								<option value="ted">German</option>
								<option value="tur">Turkish</option>
								<option value="ung">Hungarian</option>
              </AvField>
                
            </Col>

            <Col lg={6}>
                <AvField
                      name="firstname"
                      label={t('adduserspage.firstname')}
                      placeholder={t('adduserspage.fname_place')}
                      type="text"
                      errorMessage={t('adduserspage.fname_err')}
                      validate={{ required: { value: true } }}
                    />
                    <AvField
                      name="lastname"
                      label={t('adduserspage.lastname')}
                      placeholder={t('adduserspage.lname_place')}
                      type="text"
                      errorMessage={t('adduserspage.lname_err')}
                      validate={{ required: { value: true } }}
                    />
                
                    <AvField
                      name="phone"
                      label={t('adduserspage.phone')}
                      placeholder={t('adduserspage.phone_place')}
                      type="number"
                      errorMessage={t('adduserspage.phone_err')}
                    />
                <AvField type="select" onChange={this.changeCountry} value={value} name="country" label={t('adduserspage.country')}>
                <option value="">{t('adduserspage.select')}</option>
                {this.state.countries.map((item, key) =>
                    <option value={item.id}>{item.name}</option>
                )}
              
              </AvField>
              <AvField type="select" name="state" label={t('adduserspage.province')}>
              <option value="">{t('adduserspage.select')}</option>
                {this.state.states.map((item, key) =>
                    <option value={item.id}>{item.name}</option>
                )}
              
              </AvField>
              <AvField
                      name="city"
                      label={t('adduserspage.city')}
                      placeholder={t('adduserspage.city')}
                      type="text"
                    />
              {/* <AvField type="select" name="city" label={t('adduserspage.city')}>
              <option value="">{t('adduserspage.select')}</option>
                {this.state.cities.map((item, key) =>
                    <option value={item.id}>{item.name}</option>
                )}
              
              </AvField> */}
              <AvField type="textarea" name="address" id="address" placeholder={t('adduserspage.address')}>
              </AvField>
                    <AvField
                      name="postalcode"
                      label={t('adduserspage.postalcode')}
                      placeholder={t('adduserspage.postalcode')}
                      type="number"
                      errorMessage={t('adduserspage.postalcode')}
                      validate={{
                        // required: { value: true },
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: t('adduserspage.postalcode')
                        }
                      }}
                    />
                    <AvField
                      name="fiscalcode"
                      label={t('adduserspage.fiscalcode')}
                      placeholder={t('adduserspage.fiscalcode')}
                      type="text"
                      errorMessage={t('adduserspage.fiscalcode')}
                      validate={{
                        // required: { value: true },
                        pattern: {
                          value: "^[0-9a-zA-Z]+$",
                          errorMessage: t('adduserspage.fiscalcode')
                        }
                      }}
                    />
                     <AvField
                      name="piva"
                      label={t('adduserspage.piva')}
                      placeholder={t('adduserspage.piva')}
                      type="text"
                      errorMessage={t('adduserspage.piva')}
                    //   validate={{ required: { value: true } }}
                    />
                    
                  

            </Col>
            </Row>
            <FormGroup className="mb-0">
                      <div>
                        <Button type="submit" color="mm" className="mr-1">
                        {t('adduserspage.save')}
                        </Button>{" "}
                      </div>
                    </FormGroup>
            </CardBody>
            </Card>
            </AvForm>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
  };
  
  export default withTranslation() (connect(mapStatetoProps, { registerUser })(AddUser));
//export default AddUser;
