import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import axios from "axios";
import config from "./../helpers/Constants";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        }
        // else {
        //   sessionStorage.removeItem("authUser");
        // }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (users) => {
    //console.log(user);
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_name = authUser.username;
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
        logged_user: user_name,
      },
    };
    return new Promise((resolve, reject) => {
      axios.post(config.API + "add_user?company=TM", users, options).then(
        (user) => {
          //console.log(user);
          if (user.data.status === "success") {
            alert("User Added Successfully");
            window.location.reload(false);
          } else if (user.data.status === "failed") {
            alert("Username is Already Exists!!");
          } else {
            alert("There is some problem while adding user");
          }
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  editUser = (users) => {
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    };
    //console.log(user);
    return new Promise((resolve, reject) => {
      axios.post(config.API + "update_user?company=TM", users, options).then(
        (user) => {
          //console.log(user);
          if (user.data.status === "success") {
            alert("User Updated Successfully");
            window.location.reload(false);
          } else if (user.data.status === "failed") {
            alert("There is some problem while updating user");
          } else {
            alert("There is some problem while updating user");
          }
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    const options = {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    };

    return new Promise((resolve, reject) => {
      let request = {
        username: email,
        password: password,
      };
      axios.post(config.API + "login?company=TM", request, options).then(
        (user) => {
          if (user.data) {
            //console.log(user);
            var encodedUser = btoa(JSON.stringify(user.data));
            localStorage.setItem("authUser", encodedUser);
            localStorage.setItem("lang", "it");
            localStorage.setItem("ver_bar", "software");
            localStorage.setItem("diag_bar", "days");
            localStorage.setItem("map_display", "no");
            localStorage.setItem("data_visible_days", "180");
            resolve(true);
          } else {
            //console.log("user not found");
            alert("Invalid Login Credentials!!");
            resolve(true);
          }
        },
        (error) => {
          reject(this._handleError(error));
        }
      );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          console.log("yes authutils");
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
          localStorage.removeItem("authUser");
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(atob(localStorage.getItem("authUser")));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
