import React, { Component } from "react";
//import ReactApexChart from 'react-apexcharts';
import {MetaTags} from 'react-meta-tags';
//import { Progress } from "reactstrap";
//import Vector from "../Maps/Vectormap";
//import { VectorMap } from "react-jvectormap";
// const continent = queryString.parse(this.props.location.search);
// //const version_id = values.id;
// const cont = continent.code;
import { VectorMap } from '@south-paw/react-vector-maps';
//import IT from '../../components/maps/italy.json';
// import cont from '../../components/maps/india.json';
//import country from '../../components/maps/italy.json';
import styled from "@emotion/styled";
import "../../css/jquery-jvectormap.css";
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from 'moment';
import "chartist/dist/scss/chartist.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from 'query-string';
import {Sugar} from 'react-preloaders';
import $ from 'jquery';
import config from './../../helpers/Constants';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Modal,
    Card,
    CardBody,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
  } from "reactstrap";

// const progressBar = {
//   height: '5px'
// }
// const countryName = {
//   float:'left'
// }
// const countryCount = {
//   float:'right'
// }

// const countryLine = {
//   display: 'flow-root',
//   marginBottom: '0px'
// }
const strumselectOptions = {
  'iONE Cruiser': 'iONE Cruiser'
};
const socselectOptions = {
  'TM': 'Tecnomotor'
};

const fuelOptions = {
  'A': 'Diesel',
  'B': 'Petrol'
};
const lingselectOptions = {
  'it-IT': 'Italian',
  'alb': 'Albanian',
  'ara': 'Arabic',
  'bra': 'Brazilian',
  'bul': 'Bulgarian',
  'cec': 'Czech',
  'cin': 'Chinese',
  'cro': 'Croatian',
  'fin': 'Finnish',
  'fra': 'French',
  'gre': 'Greek',
  'en-US': 'English',
  'jap': 'Japanese',
  'nor': 'Norwegian',
  'ola': 'Dutch',
  'pol': 'Polish',
  'por': 'Portuguese',
  'rum': 'Romanian',
  'rus': 'Russian',
  'ser': 'Serbian',
  'spa': 'Spanish',
  'ted': 'German',
  'tur': 'Turkish',
  'ung': 'Hungarian'
};

const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

  const Map = styled.div`
  margin: 1rem auto;

  svg {
    stroke: #fff;

    // All layers are just path elements
    path {
      fill: #e7e7e7;
      cursor: pointer;
      outline: none;

      // When a layer is hovered
      &:hover {
        fill: rgba(236, 69, 97);
      }

      // When a layer is focused.
      &:focus {
        fill: rgba(196, 39, 66);
      }

      // When a layer is 'checked' (via checkedLayers prop).
      &[aria-checked='true'] {
        fill: rgba(252, 203, 0);
      }

      // When a layer is 'selected' (via currentLayers prop).
      &[aria-current='true'] {
        fill: rgba(252, 203, 0);
      }

      // You can also highlight a specific layer via it's id
      &[id="nz-can"] {
        fill: rgba(56,43,168,0.6);
      }
    }
  }
`;

class DiagnosisCountry extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;

    this.state = {
      //value: localStorage.getItem('lang'),
      products: [],
      continent_name: cont,
      visible: false,
      modal_standard: false,
      default_date: new Date(),
      default: false,
      start_date: new Date(),
      end_date: new Date(),
      date: new Date(),
      tablewidth: 9,
      export_btn:false,
      loading: true
    };
    
    this.tog_standard = this.tog_standard.bind(this);
    i18next.changeLanguage(localStorage.getItem('lang'));
       // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.getFilteredDateData = this.getFilteredDateData.bind(this);
  }

   //DatePicker
   handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }
  
  handleEnd(date) {
    this.setState({ end_date: date });
  }

  goBack() {
    window.history.back();
  }

  tog_map() {
    document.getElementsByClassName("map_div")[0].style.display = "none";
    document.getElementsByClassName("hide_map")[1].style.display = "none";
    document.getElementsByClassName("show_map")[0].style.display = "block";
    localStorage.setItem('map_display', "no");
    //document.body.classList.add("no_map");
    this.setState({ tablewidth: 12 });
    //this.setState({ mapdisplay: false });

  }

  show_map() {
    document.getElementsByClassName("map_div")[0].style.display = "block";
    document.getElementsByClassName("hide_map")[1].style.display = "block";
    document.getElementsByClassName("show_map")[0].style.display = " none";
    //document.body.classList.add("no_map");
    localStorage.setItem('map_display', "yes");
    this.setState({ tablewidth: 9 });
    //this.setState({ mapdisplay: false });

  }
  
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  handleClick = (e, countryCode) => {
    alert('You Have Selected '+ countryCode);

 };

componentDidMount() {
  $('.vertical-center i').on('click',function(){
    var pwidth = 400;

    if($(this).hasClass('left')){
      $('.react-bootstrap-table').scrollLeft( $('.react-bootstrap-table').scrollLeft()-pwidth );
    } else {
        $('.react-bootstrap-table').scrollLeft($('.react-bootstrap-table').scrollLeft()+pwidth );
    };


  });

  let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    if(authUser.role === "Administrator" || authUser.role === "Diagnosis Manager" || authUser.role === "Administrator and Site Org" || authUser.role === "Commercial"){
      this.setState({
        export_btn: true,
        map_btn: true
      })
      
    }

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.diagnosis === 0){
        window.history.back();
      }
    })
  const map_show = localStorage.getItem('map_display');
  if(map_show === "yes"){
  document.getElementsByClassName("show_map")[0].style.display = "none";
  document.getElementsByClassName("map_div")[0].style.display = "block";
  document.getElementsByClassName("hide_map")[1].style.display = "block";
  this.setState({
    tablewidth: 9,
  })
  
  }else{
  
  document.getElementsByClassName("show_map")[0].style.display = "block";
  document.getElementsByClassName("map_div")[0].style.display = "none";
  document.getElementsByClassName("hide_map")[1].style.display = "none";
  this.setState({
    tablewidth: 12,
  })
  }
const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;
//var country = require('../../components/maps/'+cont+'.json');
const data_visible_days = localStorage.getItem('data_visible_days');
const selected_product = localStorage.getItem('sel_product');

//var days = "14";
if(!data_visible_days){
  var days = "14";
}else{
   days = data_visible_days;
}
if(!selected_product){
  var select_product = "all";
}else{
  select_product = selected_product;
}

fetch(config.API+'diagnosis_country_data/'+cont+'?days='+days+'&sel_product='+select_product+'&company=TM', {
  headers: {  
    'Nexion_API_Key': config.API_KEY
  }
}).then((Response)=>Response.json()).then((findresponse)=>{
  console.log(findresponse)
  this.setState({
    products:findresponse,
    loading: false
  })
  
})
}

getFilteredDateData() {
const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;
const selected_product = localStorage.getItem('sel_product');
const starting_date = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
const ending_date =moment(this.state.end_date).format('YYYY-MM-DD HH:mm:ss');
if(!selected_product){
  var select_product = "all";
}else{
  select_product = selected_product;
}

fetch(config.API+'diagnosis_country_data/'+cont+'?starting_date='+starting_date+'&ending_date='+ending_date+'&sel_product='+select_product+'&company=TM', {
  headers: {  
    'Nexion_API_Key': config.API_KEY
  }
}).then((Response)=>Response.json()).then((findresponse)=>{
  //console.log(findresponse)
  this.setState({
    products:findresponse,
  })
})
this.tog_standard();
}
resetpage() {
window.location.reload(false);
}

  render() {

    const mapclick = ({ target }) => {
        const name = target.attributes.name.value;
        alert("you have clicked on test "+name);
        // window.open(`https://www.google.com/search?q=${name}%20nz`)
      }
    const { t } = this.props;
    const columns = [
      {
        text: t('diagnosispage.diagnosis_id'),
        dataField: "id",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.ser_no'),
        dataField: "serial_number",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          //const sn_no = row.serial_number;
         const id = row.id;
          return (    
            <Link to={"/diagnosis_details?diagnosis_id="+id} target="_blank"><button className='btn btn-outline-danger btn lg'>{row.serial_number}</button></Link>
          )
        }
      },
      {
        text: t('diagnosispage.strum'),
        dataField: "device_name",
        filter: selectFilter({
          options: strumselectOptions
      })
      },
      {
        text: t('diagnosispage.brand'),
        dataField: "brand",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.template'),
        dataField: "family",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.guy'),
        dataField: "chassis",
        filter: textFilter(),
      },
      {
        
        text: t('diagnosispage.fuel'),
        dataField: "fuel",
        formatter: (rowContent, row) => {
          const fuel = row.fuel;
          if(fuel === "D"){
            var fuels = "Diesel";
          }else{
            fuels= "Petrol";
          }
          //const id = row.id;
          return (    
              fuels
          )
        },
        filter: selectFilter({
          options: fuelOptions
      }),
      },
      {
        text: t('diagnosispage.year'),
        dataField: "variant_year_from",
        filter: textFilter(),
        formatter: (rowContent, row) => {
          const s_year = row.variant_year_from;
          const e_year = row.variant_year_to;
          
          //const id = row.id;
          return (    
              s_year+ "-" + e_year
          )
        },
      },
      {
        text: "BUS ECU Status",
        formatter: (rowContent, row) => {
          var bus_count = row.bus_count.bus_count;
          if(bus_count === 0){
            return (    
              <i className="fas fa-info-circle no_bus"></i>
            )
          }else if(bus_count > 0){
            if(row.ok_count.ok_count > 0){
              return (    
                <i className="fas fa-check-circle one_ecu_green"></i>
              )
            }else{
              return (    
                <i className="fas fa-times-circle all_ecu_red"></i>
              )
            }
          }
        },
      },
      {
        text: "VIN",
        dataField: "vin",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.id_riga'),
        dataField: "plate_number",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.kw'),
        dataField: "kw",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.engine_code'),
        dataField: "engine_code_clear",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.version'),
        dataField: "software",
        filter: textFilter(),
      },
      {
        text: t('diagnosispage.ver_db'),
        dataField: "vehicle_database",
        filter: textFilter(),
      },
      {
          text: t('diagnosispage.soc'),
          dataField: "company",
          filter: selectFilter({
            options: socselectOptions
        }),
        },
        {
          text: t('diagnosispage.ling'),
          dataField: "language",
          filter: selectFilter({
            options: lingselectOptions
        }),
        },
        {
          text: t('diagnosispage.diag_date'),
          dataField: "diagnosis_date",
          filter: dateFilter(),
          formatter: (rowContent, row) => {
            var up_date = row.diagnosis_date.replace(/T/, ' ').replace(/\..+/, '');
          //const id = row.id;
          return (    
            up_date
          )
        },
        }
  ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versionpage.firstPageText'),
      prePageText: t('versionpage.prePageText'),
      nextPageText: t('versionpage.nextPageText'),
      lastPageText: t('versionpage.lastPageText'),
      nextPageTitle: t('versionpage.nextPageTitle'),
      prePageTitle: t('versionpage.prePageTitle'),
      firstPageTitle: t('versionpage.firstPageTitle'),
      lastPageTitle: t('versionpage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: '10', value: 10
        }, 
        {
          text: '50', value: 50
        },{
          text: '100', value: 100
        }
      ]
    };
    const continent = queryString.parse(this.props.location.search);
//const version_id = values.id;
const cont = continent.code;
var country = require('../../components/maps/'+cont+'.json');
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          <ToolkitProvider
            keyField="id"
            data={ this.state.products }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'Diagnosi Data '+this.state.date+'.csv',} }
            >
            {
                props => (
                <div>
                    <ul className="export_btns">
                    <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li>
                        {this.state.export_btn === true ? 
                      <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({ btnprimary1: !this.state.btnprimary1 })
                        }
                      >
                      <DropdownToggle tag="button" className="btn btn-success">
                      {t('diagnosispage.exp')}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem><ExportCSVButton { ...props.csvProps }>{t('diagnosispage.exp_sel')}</ExportCSVButton></DropdownItem>
                        <DropdownItem><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"Diagnosi Data "+this.state.date+".csv"} data={this.state.products}>{t('diagnosispage.exp_all')}</CSVLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                      </li> : ""
                      }
                      <li><button
                          type="button"
                          onClick={() => this.tog_map()}
                          className="btn hide_map btn-danger waves-effect waves-light"
                        >
                          {t('diagnosispage.hide_map')}
                        </button></li>
                        <li><button
                          type="button"
                          onClick={() => this.show_map()}
                          className="btn show_map btn-danger waves-effect waves-light"
                        >
                          {t('diagnosispage.show_map')}
                        </button></li>
                        
                        {/* <li><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></li> */}
                    </ul>
                        <br/>
                        <br/>
                        <hr/>
                        <div className="table-wrapper">
                    <div className="vertical-center"><i class="ion ion-ios-arrow-dropleft-circle left"></i><i class="ion ion-ios-arrow-dropright-circle right"></i></div>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    filter={ filterFactory() }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive"
                    />
                    </div>
                </div>
                )
            }
            </ToolkitProvider>
            
            
      </div>
    );
    return (
        
      <React.Fragment>
        <MetaTags>
      <title>Diagnosis | Technomotor Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                          {t('diagnosispage.get_ver_data')}
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          
                          <Form action="#">
                              <FormGroup className="mb-0 forminput">
                             
                              <Row>
                                <Col md={12}>
                                  <label>{t('diagnosispage.start_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.start_date}
                                    selectsStart
                                    id="date_pick"
                                    className="date_pick form-control"
                                    placeholderText="From"
                                    name="start_dates"
                                    value={this.state.start_date} 
                                    // onChange={this.handleEmailChange}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleStart}
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col md={12}>
                                <label>{t('diagnosispage.end_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.end_date}
                                    selectsEnd
                                    id="date_pick"
                                    className="form-control"
                                    name="end_dates"
                                    value={this.state.end_date}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleEnd}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            onClick={this.resetpage}
                            className="btn btn-warning waves-effect"
                            data-dismiss="modal"
                          >
                            {t('diagnosispage.reset_filter')}
                          </button>
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            {t('diagnosispage.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={this.getFilteredDateData}
                            className="btn filter_btn waves-effect waves-light"
                          >
                            {t('diagnosispage.proceed')}
                          </button>
                        </div>
                      </Modal>
        <div className="container-fluid homepageFirstRow">
        <Card>
          <CardBody>   
          <Row>
            <Col xl={3} className="map_div">
            <Card>
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.countries')}</h4>
                  {/* <p>{t('homepage.last_14_days')}</p> */}
                  <button onClick={() => this.goBack()} className="btn hide_map btn-dark waves-effect waves-light float_right"><i className="far fa-arrow-alt-circle-left"></i> Continent Map</button>
                  <br/><br/><br/>
                <Row>
                  {/* <Col xl={3}>
                  <p className="card-title-desc">
                    <b>9,114 - {t('homepage.all_tests')}</b>
                  </p>

                  <div className="">
                    <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>1896</span></p>
                      <Progress style={progressBar} color="danger" value={100}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>647</span></p>
                    <Progress style={progressBar} color="danger" value={40}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United Kindom</span><span style={countryCount}>575</span></p>
                    <Progress style={progressBar} color="danger" value={35}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>558</span></p>
                    <Progress style={progressBar} color="danger" value={33}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>350</span></p>
                    <Progress style={progressBar} color="danger" value={25}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>321</span></p>
                    <Progress style={progressBar} color="danger" value={23}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>289</span></p>
                    <Progress style={progressBar} color="danger" value={20}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Australia</span><span style={countryCount}>287</span></p>
                    <Progress style={progressBar} color="danger" value={19}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>273</span></p>
                    <Progress style={progressBar} color="danger" value={15}></Progress>
                    </div>
                    
                  </div>
                  </Col> */}
                  <Col xl={12}>
                  <div style={{ width: this.props.width, height: 420 }}>
                  <Map>
                  <VectorMap {...country} layerProps={{ mapclick }} />
                  </Map>
                    </div>
                  </Col>
                </Row>
                
                </CardBody>
            </Card>
            </Col>

            <Col xl={this.state.tablewidth}>
            <Card>
                <CardBody>
                <Row>
                <PaginationProvider
                    pagination={
                        paginationFactory(options)
                    }
                    >
                    { contentTable }
                  </PaginationProvider>
                </Row>
                </CardBody>
            </Card>
            </Col>
          </Row>
          </CardBody>
          </Card>
          {/* <Row>

            <Col xl={3}>
            <Card>
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system_category')}</h4>
                
                <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
            </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system')}</h4>
                
                <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
            </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.eobd')}</h4>
                
                <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
            </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.ok_function')}</h4>
                <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
            </Card>
            </Col>  
         </Row>
       */}
          
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(DiagnosisCountry);
