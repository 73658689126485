import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
//import { Link } from "react-router-dom";

import {MetaTags} from 'react-meta-tags';
//import { Progress } from "reactstrap";
//import Vector from "../Maps/Vectormap";
import '../../assets/scss/custom.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from "react-router-dom";
//import ReactApexChart from 'react-apexcharts';
import "chartist/dist/scss/chartist.scss";
import { Bar } from 'react-chartjs-2';
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';
//import Clock from 'react-live-clock';

// const progressBar = {
//   height: '5px'
// }
// const countryName = {
//   float:'left'
// }
// const countryCount = {
//   float:'right'
// }

// const countryLine = {
//   display: 'flow-root',
//   marginBottom: '0px'
// }


class Homepage extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem('lang'),
      version:[],
      diagnosis:[],
      users:[],
      ver_count:[],
      version_list:[],
      version_items:[],
      diag_list:[],
      diag_items:[],
      loading:true,
      diagnosis_visiblity: false,
      version_visiblity: false,
      diagnosis_test_visiblity: false,
      version_test_visiblity: false,
      users_visiblity: false,
      statistic_visibility: false,
      ver_bar_selected: localStorage.getItem('ver_bar'),
      diag_bar_selected: localStorage.getItem('diag_bar')
    };
    i18next.changeLanguage(localStorage.getItem('lang'));
    this.handleVerBarChange = this.handleVerBarChange.bind(this);
    this.handleDiagBarChange = this.handleDiagBarChange.bind(this);
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
      //console.log(findresponses);

      if(findresponses.homestats === 1){
        this.setState({
          statistic_visibility: true
        })
      }
      if(findresponses.users === 1){
        this.setState({
          users_visiblity: true
        })
      }
      if(findresponses.diagnosis === 1){
        this.setState({
          diagnosis_visiblity:true
        })
      }
      if(findresponses.versions === 1){
        this.setState({
          version_visiblity: true
        })
      }
      if(findresponses.diag_test === 1){
        this.setState({
          diagnosis_test_visiblity:true
        })
      }
      if(findresponses.ver_test === 1){
        this.setState({
          version_test_visiblity: true
        })
      }
    })

    var ver_bar = localStorage.getItem('ver_bar');
    if(ver_bar){
      var ver_bar_n = ver_bar;
    }else{
      ver_bar_n = "software";
    }
    //console.log(ver_bar);
    fetch(config.API+'get_home_stats/'+ver_bar_n+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        diagnosis:findresponse.diagnosis,
        version:findresponse.version,
        users:findresponse.users,
        ver_count:findresponse.version_count,
        version_list:findresponse.version_list.v_list,
        version_items:findresponse.version_list.v_items,
        //loading:false
      })
    })

    var diag_bar = localStorage.getItem('diag_bar');
   
    //console.log(ver_bar);
    fetch(config.API+'get_diag_stats/'+diag_bar+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    
      this.setState({
        diag_list:findresponse.diagnosis_items.d_list,
        diag_items:findresponse.diagnosis_items.d_items,
        loading:false
      })
    })
  }

    handleVerBarChange(prod) {
      localStorage.setItem('ver_bar', prod.target.value);
      //alert(lang.target.value);
      var ver_bar = prod.target.value;
      this.setState({
        ver_bar_selected:prod.target.value
      });
    //console.log(ver_bar);
    fetch(config.API+'get_home_stats/'+ver_bar+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    
      this.setState({
        diagnosis:findresponse.diagnosis,
        version:findresponse.version,
        users:findresponse.users,
        ver_count:findresponse.version_count,
        version_list:findresponse.version_list.v_list,
        version_items:findresponse.version_list.v_items,
        loading:false
      })
    })
      //window.location.reload(false);
    }

    handleDiagBarChange(prod) {
      localStorage.setItem('diag_bar', prod.target.value);
      //alert(lang.target.value);
      var diag_bar = prod.target.value;
      this.setState({
        diag_bar_selected:prod.target.value
      });
    //console.log(ver_bar);
    fetch(config.API+'get_diag_stats/'+diag_bar+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    
      this.setState({
        diag_list:findresponse.diagnosis_items.d_list,
        diag_items:findresponse.diagnosis_items.d_items,
        loading:false
      })
    })
      //window.location.reload(false);
    }

  render() {
    const { t } = this.props;

    const data = {
      labels: this.state.version_list,
      datasets: [
          {
              label: t('homepage.instruments'),
              backgroundColor: "#ec4561",
              borderColor: "#ec4561",
              borderWidth: 1,
              hoverBackgroundColor: "#ec4561",
              hoverBorderColor: "#ec4561",
              data: this.state.version_items
          }
      ]

  };

  const diagdata = {
    labels: this.state.diag_list,
    datasets: [
        {
            label: t('homepage.diagnosis'),
            backgroundColor: "#002C77",
            borderColor: "#002C77",
            borderWidth: 1,
            hoverBackgroundColor: "#002C77",
            hoverBorderColor: "#002C77",
            data: this.state.diag_items
        }
    ]

};

  const option = {
    tootlbar: {
        show: false
    },
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                var dataset = data.datasets[tooltipItem.datasetIndex];
                //var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                //var total = meta.total;
                var currentValue = dataset.data[tooltipItem.index];
                //var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                return currentValue;
            },
            title: function (tooltipItem, data) {
                return data.labels[tooltipItem[0].index];
            }
        }
    }
}

const option_diag = {
  tootlbar: {
      show: false
  },
  tooltips: {
      callbacks: {
          label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              //var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              //var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              //var percentage = parseFloat((currentValue / total * 100).toFixed(1));
              return currentValue;
          },
          title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
          }
      }
  }
}
    return (
      <React.Fragment>
        <MetaTags>
      <title>Home | Tecnomotor Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Tecnomotor Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
        <div className="container-fluid">
        <Row className="homepageFirstRow">
          <Col xl={2}>
            <Card>
            <Link to="/dashboard" className="home_link">
              <CardBody className="text-center homeIconCardBody">
              <i class="ion ion-ios-speedometer text-danger text-center homeIcon"></i>
              <p>{t('menus.dashboard')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col>
          {/* <Col xl={2}>
            <Card>
              <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-time text-danger text-center homeIcon"></b>
              <p>{t('menus.logs')}</p>
              </CardBody>
            </Card>
          </Col> */}
          { this.state.users_visiblity === true ?
          <Col xl={2}>
          <Card>
          <Link to="/users" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-person text-danger text-center homeIcon"></b>
              <p>{t('menus.users')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col> : ""
          }
          { this.state.diagnosis_visiblity === true ?
          <Col xl={2}>
          <Card>
          <Link to="/diagnosis" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-medkit text-danger text-center homeIcon"></b>
              <p>{t('menus.diagnosis')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col> : ""
          }

          {/* { this.state.diagnosis_test_visiblity === true ?
          <Col xl={2}>
          <Card>
          <Link to="/diagnosis_test" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-medkit text-danger text-center homeIcon"></b>
              <p>Diagnosi Test</p>
              </CardBody>
              </Link>
            </Card>
          </Col> : ""
          } */}

         { this.state.version_visiblity === true ?
          <Col xl={2}>
          <Card>
          <Link to="/versions" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-list text-danger text-center homeIcon"></b>
              <p>{t('menus.versions')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col> : ""
          }
          {/* { this.state.version_test_visiblity === true ?
          <Col xl={2}>
          <Card>
          <Link to="/versions_test" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-list text-danger text-center homeIcon"></b>
              <p>Versioni Test</p>
              </CardBody>
              </Link>
            </Card>
          </Col> : ""
          } */}
          {/* <Col xl={2}>
          <Card>
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-construct text-danger text-center homeIcon"></b>
              <p>{t('menus.parts')}</p>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl={2}>
          <Card>
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-cog text-danger text-center homeIcon"></b>
              <p>{t('menus.parameters')}</p>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl={2}>
          <Card>
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-alert text-danger text-center homeIcon"></b>
              <p>{t('menus.error_all')}</p>
              </CardBody>
            </Card>
          </Col> */}
          
          </Row>
          <Row>
          {/* <Col xl={2}>
          <Card>
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-bug text-danger text-center homeIcon"></b>
              <p>{t('menus.eobd_errors')}</p>
              </CardBody>
            </Card>
          </Col> */}
          {/* <Col xl={2}>
          <Card>
          <Link to="/diagnosis" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-medkit text-danger text-center homeIcon"></b>
              <p>{t('menus.diagnosis')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col>
          <Col xl={2}>
          <Card>
          <Link to="/versions" className="home_link">
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-list text-danger text-center homeIcon"></b>
              <p>{t('menus.versions')}</p>
              </CardBody>
              </Link>
            </Card>
          </Col> */}
          {/* <Col xl={2}>
          <Card>
          <CardBody className="text-center homeIconCardBody">
              <b class="ion ion-ios-key text-danger text-center homeIcon"></b>
              <p>{t('menus.secure_gateway')}</p>
              </CardBody>
            </Card>
          </Col> */}
          
        </Row>
            {/* <Col xl={5}>
            <Card>
                <CardBody className="height-600">
                <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.countries')}</h4> */}
                  {/* <p>{t('homepage.last_14_days')}</p> */}
                {/* <Row> */}
                  {/* <Col xl={4}>
                  <p className="card-title-desc">
                    <b>9,114 - {t('homepage.all_tests')}</b>
                  </p>

                  <div className="">
                    <div className="mb-10">
                      <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>1896</span></p>
                      <Progress style={progressBar} color="danger" value={100}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>647</span></p>
                    <Progress style={progressBar} color="danger" value={40}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United Kindom</span><span style={countryCount}>575</span></p>
                    <Progress style={progressBar} color="danger" value={35}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>558</span></p>
                    <Progress style={progressBar} color="danger" value={33}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>350</span></p>
                    <Progress style={progressBar} color="danger" value={25}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>321</span></p>
                    <Progress style={progressBar} color="danger" value={23}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>289</span></p>
                    <Progress style={progressBar} color="danger" value={20}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Australia</span><span style={countryCount}>287</span></p>
                    <Progress style={progressBar} color="danger" value={19}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>273</span></p>
                    <Progress style={progressBar} color="danger" value={15}></Progress>
                    </div>
                    
                  </div>
                  </Col> */}
                  {/* <Col xl={12}>
                  <div id="world-map-markers" class="vector-map-height">
                    <Vector
                      value="world_mill"
                      width="100%"
                      color="rgb(255, 210, 0)"
                    />
                  </div>
                  </Col>
                </Row>
                
                </CardBody>
            </Card>
            </Col> */}
              <Row>
                <Col xl={6}>
                  <Card>
                    <CardBody className="height-200">
                    <h4 className="card-title"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.version')}</h4>
                    {/* <p>{t('homepage.last_14_days')}</p> */}
                    <div className="text-center ">
                <h1 className="text-center text-danger">{this.state.version.length === 0 ? 0 : this.state.version}</h1>
                        <p className="font-size-14 pt-1">{t('homepage.instruments')}</p>
                    </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={6}>
                  <Card>
                    <CardBody className="height-200">
                    <h4 className="card-title"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.diagnosis')}</h4>
                    {/* <p>{t('homepage.last_14_days')}</p> */}
                    <div className="text-center ">
                        <h1 className="text-center text-danger">{this.state.diagnosis.length === 0 ? 0 : this.state.diagnosis}</h1>
                        <p className="font-size-14 pt-1">{t('homepage.diagnosis')}</p>
                    </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col xl={3}>
                  <Card>
                    <CardBody className="height-200">
                    <h4 className="card-title"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.users')}</h4>
                    
                    <div className="text-center py-4">
                        <h1 className="text-center text-danger">{this.state.users}</h1>
                        
                    </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            <Row>
                <Col xl={6}>
                <Card>
                  <CardBody>
                  <h4 className="card-title mb-4">{t('homepage.version')}</h4>
                  <Row>
                    <Col xl={5}>
                    <select className="form-control" value={this.state.ver_bar_selected} onChange={this.handleVerBarChange}>
                      <option value="software">Software</option>
                      <option value="vehicle_database">Database Sel. Veicolo</option>
                      <option value="ok_function_database">Database Funzioni OK</option>
                      <option value="plants_database">Database Impianti</option>
                      <option value="language_database">Database Traduzioni</option>
                      <option value="autodiagnosis">Database Diagnosi</option>
                      <option value="java">JAVA</option>
                      <option value="generic">Generic</option>
                      <option value="sw_update">Update</option>
                      <option value="sgw_fca_library">SGW FCA Library</option>
                      <option value="scripts">Scripts</option>
                      <option value="kernel">Kernel</option>
                      <option value="j2534">J2534</option>
                      <option value="j2534_k">J2534_K</option>
                      <option value="j2534_iso_tp_2">J2534_ISO_TP_2</option>
                      <option value="iso22900">ISO22900</option>
                      <option value="firmware">Firmware</option>
                  </select>
                    </Col>
                    <Col xl={7}>
                    </Col>
                  </Row>
                  
                  <React.Fragment>
                    <Bar width={500} height={250} data={data} options={option} />
                </React.Fragment>
                  </CardBody>
                </Card>
               
                </Col>
                <Col xl={6}>
                <Card>
                  <CardBody>
                  <h4 className="card-title mb-4">{t('homepage.diagnosis')}</h4>
                  <Row>
                    <Col xl={3}>
                    <select className="form-control" value={this.state.diag_bar_selected} onChange={this.handleDiagBarChange}>
                      <option value="year">Years</option>
                      <option value="month">Months</option>
                      <option value="days">Days</option>
                      
                  </select>
                    </Col>
                    <Col xl={9}>
                    </Col>
                  </Row>
                  <React.Fragment>
                    <Bar width={500} height={250} data={diagdata} options={option_diag} />
                </React.Fragment>
                  </CardBody>
                </Card>
               
                </Col>
              
          </Row>
          {/* <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system_category')}</h4>
                  <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.system')}</h4>
                  <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.eobd')}</h4>
                  <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4"><i className="ion ion-md-analytics text-danger"></i> {t('homepage.ok_function')}</h4>
                  <div id="chart">
                <ReactApexChart options={this.state.options} series={this.state.series} type="donut" />
                </div>
                </CardBody>
              </Card>
            </Col>
            </Row> */}
      
          
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Homepage);
