import React, { Component } from "react";
import {MetaTags} from 'react-meta-tags';
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import moment from 'moment';
import "chartist/dist/scss/chartist.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import filterFactory, { textFilter, selectFilter, dateFilter } from 'react-bootstrap-table2-filter';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Modal,
    Card,
    CardBody,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
  } from "reactstrap";
  import {Sugar} from 'react-preloaders';
  import $ from 'jquery';
  import config from './../../helpers/Constants';
  import axios from "axios";

const socselectOptions = {
  'MM': 'Magneti Marelli',
  'TM': 'Tecnomotor'
};

const fuelOptions = {
  '1': 'Benzina',
  '2': 'Diesel',
  '3': 'FlexiFuel',
  '4': 'Metano',
  '5': 'Bifuel GAS',
  '6': 'Elettrica',
  '7': 'Bifuel Metano',
  '9': 'Ibrida'
};

const lingselectOptions = {
  'it-IT': 'Italian',
  'alb': 'Albanian',
  'ara': 'Arabic',
  'bra': 'Brazilian',
  'bul': 'Bulgarian',
  'cec': 'Czech',
  'cin': 'Chinese',
  'cro': 'Croatian',
  'fin': 'Finnish',
  'fra': 'French',
  'gre': 'Greek',
  'en-US': 'English',
  'jap': 'Japanese',
  'nor': 'Norwegian',
  'ola': 'Dutch',
  'pol': 'Polish',
  'por': 'Portuguese',
  'rum': 'Romanian',
  'rus': 'Russian',
  'ser': 'Serbian',
  'spa': 'Spanish',
  'ted': 'German',
  'tur': 'Turkish',
  'ung': 'Hungarian'
};

const cksselectOptions = {
    'OK': 'OK',
    'NOK': 'NOK'
};

const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

class AcOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      visible: false,
      modal_standard: false,
      default_date: new Date(),
      default: false,
      start_date: new Date(),
      end_date: new Date(),
      date: new Date(),
      export_btn:false,
      logged_user:'',
      loading: true
    };
    
    this.tog_standard = this.tog_standard.bind(this);
    i18next.changeLanguage(localStorage.getItem('lang'));
       // DatePicker
    this.handleDefault = this.handleDefault.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.getFilteredDateData = this.getFilteredDateData.bind(this);
    this.handleExportSubmit = this.handleExportSubmit.bind(this);
  }

   //DatePicker
   handleDefault(date) {
    this.setState({ default_date: date });
  }
  handleAutoClose(date) {
    this.setState({ auto_close: date });
  }

  handleStart(date) {
    this.setState({ start_date: date });
  }
  
  handleEnd(date) {
    this.setState({ end_date: date });
  }
  
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

componentDidMount() {
  let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var user_name = authUser.username;
    //console.log(authUser);
    this.setState({
      logged_user:user_name
  })
    if(authUser.role === "Administrator" || authUser.role === "Diagnosis Manager" || authUser.role === "Administrator and Site Org" || authUser.role === "Commercial"){
      this.setState({
        export_btn: true
      })
      
    }

  fetch(config.API+'get_user_permissions/'+user_id, {
    headers: {  
      'Nexion_API_Key': config.API_KEY
    }
  }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
    if(findresponses.ac_operations === 0){
      window.history.back();
    }
  })

    $('.vertical-center i').on('click',function(){
      var pwidth = 400;

      if($(this).hasClass('left')){
        $('.react-bootstrap-table').scrollLeft( $('.react-bootstrap-table').scrollLeft()-pwidth );
      } else {
          $('.react-bootstrap-table').scrollLeft($('.react-bootstrap-table').scrollLeft()+pwidth );
      };
    });

const data_visible_days = localStorage.getItem('data_visible_days');
const selected_product = localStorage.getItem('sel_product');

if(!data_visible_days){
  var days = "14";
}else{
   days = data_visible_days;
}
if(!selected_product){
  var select_product = "all";
}else{
  select_product = selected_product;
}

fetch(config.API+'ac_man_operations_test?days='+days+'&sel_product='+select_product, {
  headers: {  
    'Nexion_API_Key': config.API_KEY
  }
}).then((Response)=>Response.json()).then((findresponse)=>{
  
  this.setState({
    products:findresponse,
    loading: false
  })
  
})
}

getFilteredDateData() {
const selected_product = localStorage.getItem('sel_product');
const starting_date = moment(this.state.start_date).format('YYYY-MM-DD HH:mm:ss');
const ending_date =moment(this.state.end_date).format('YYYY-MM-DD HH:mm:ss');
if(!selected_product){
  var select_product = "all";
}else{
  select_product = selected_product;
}

fetch(config.API+'ac_man_operations_test?starting_date='+starting_date+'&ending_date='+ending_date+'&sel_product='+select_product, {
  headers: {  
    'Nexion_API_Key': config.API_KEY
  }
}).then((Response)=>Response.json()).then((findresponse)=>{
  this.setState({
    products:findresponse,
  })
})
this.tog_standard();
}

handleExportSubmit(event, values) {
  var exp_values = {
    'type': "Export",
    'note': "AC Manual Operations Export",
    'company': "Nexion"
  }
  const username = this.state.logged_user;
  const options = {
    headers: {
      'Nexion_API_Key': config.API_KEY,
      'logged_user':username
    }
  };
  return new Promise((resolve, reject) => {
  
    axios.post(config.API+'export_import_logs', exp_values, options)
      .then(
        user => {
        },
        error => {
          reject(this._handleError(error));
        })
  });

}

resetpage() {
window.location.reload(false);
}

  render() {
    const { t } = this.props;
    const columns = [
      {
        text: t('diagnosispage.diagnosis_id'),
        dataField: "id",
        filter: textFilter(),
      },
        {
          text: t('diagnosispage.ser_no'),
          dataField: "serial_number",
          filter: textFilter(),
          formatter: (rowContent, row) => {
           const id = row.id;
            return (    
              <Link to={"/ac_man_operations_details_test?operation_id="+id}><button className='btn btn-outline-danger btn lg'>{row.serial_number}</button></Link>
            )
          }
        },
        {
          text: t('diagnosispage.strum'),
          dataField: "device_name",
          filter: textFilter(),
        },
        {
            text: "CMD",
            dataField: "cmd",
            filter: textFilter(),
        },
        {
          text: "Operation Result",
          dataField: "op_result",
          filter: textFilter(),
      },
      {
        text: "Gas Insertion",
        dataField: "gas_insertion",
        filter: textFilter(),
      },
      {
        text: "Gas Recovery",
        dataField: "gas_recovery",
        filter: textFilter(),
      },
      {
        text: "Oil Insertion",
        dataField: "oil_insertion",
        filter: textFilter(),
      },
      {
        text: "Oil Recovery",
        dataField: "oil_recovery",
        filter: textFilter(),
      },
      {
        text: "Vacuum",
        dataField: "vacuum",
        filter: textFilter(),
      },
      {
        text: "Leaks",
        dataField: "leaks",
        filter: textFilter(),
      },
        {
          text: "BOOTLOADER",
          dataField: "bootloader",
          filter: textFilter(),
        },
        {
          text: "HMI",
          dataField: "hmi",
          filter: textFilter(),
        },
        {
            text: "PLC",
            dataField: "plc",
            filter: textFilter(),
        },
        {
          text: "Database",
          dataField: "vehicle_database",
          filter: textFilter(),
        },
        {
            text: t('diagnosispage.soc'),
            dataField: "company",
            filter: selectFilter({
              options: socselectOptions
          }),
          },
          {
            text: t('diagnosispage.ling'),
            dataField: "language",
            filter: selectFilter({
              options: lingselectOptions
          }),
          },
          {
            text: t('diagnosispage.diag_date'),
            dataField: "diagnosis_date",
            filter: dateFilter(),
            formatter: (rowContent, row) => {
            var up_date = row.diagnosis_date.replace(/T/, ' ').replace(/\..+/, '');
            return (    
              up_date
            )
          },
          }
  ];
    const options = {
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('versionpage.firstPageText'),
      prePageText: t('versionpage.prePageText'),
      nextPageText: t('versionpage.nextPageText'),
      lastPageText: t('versionpage.lastPageText'),
      nextPageTitle: t('versionpage.nextPageTitle'),
      prePageTitle: t('versionpage.prePageTitle'),
      firstPageTitle: t('versionpage.firstPageTitle'),
      lastPageTitle: t('versionpage.lastPageTitle'),
      showTotal: true,
      showTotalTitle: "asjkas",
      totalSize: this.state.products.length,
      sizePerPageList: [
        {
          text: '10', value: 10
        }, 
        {
          text: '50', value: 50
        },{
          text: '100', value: 100
        }
      ]
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          <ToolkitProvider
            keyField="id"
            data={ this.state.products }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'Diagnosi Data '+this.state.date+'.csv',} }
            >
            {
                props => (
                <div>
                    <ul className="export_btns">
                    <li><button
                          type="button"
                          onClick={this.tog_standard}
                          className="btn filter_btn waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="ti-filter"></i>
                        </button></li>
                        {this.state.export_btn === true ? 
                      <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({ btnprimary1: !this.state.btnprimary1 })
                        }
                      >
                      <DropdownToggle tag="button" className="btn btn-success">
                      {t('diagnosispage.exp')}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.handleExportSubmit}><ExportCSVButton { ...props.csvProps }>{t('diagnosispage.exp_sel')}</ExportCSVButton></DropdownItem>
                        <DropdownItem onClick={this.handleExportSubmit}><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"Diagnosi Data "+this.state.date+".csv"} data={this.state.products}>{t('diagnosispage.exp_all')}</CSVLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                      </li> : ""
                      }
                    </ul>
                        <br/>
                        <br/>
                        <hr/>
                    <div className="table-wrapper">
                    <div className="vertical-center"><i class="ion ion-ios-arrow-dropleft-circle left"></i><i class="ion ion-ios-arrow-dropright-circle right"></i></div>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    filter={ filterFactory() }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive"
                    />
                    </div>
                    
                </div>
                )
            }
            </ToolkitProvider>
            
            
      </div>
    );
    return (
      <React.Fragment>
        <MetaTags>
      <title>Test AC Manual Operations | Technomotor Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
      <Modal
                        isOpen={this.state.modal_standard}
                        toggle={this.tog_standard}
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0" id="myModalLabel">
                          {t('diagnosispage.get_ver_data')}
                          </h5>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({ modal_standard: false })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          
                          <Form action="#">
                              <FormGroup className="mb-0 forminput">
                             
                              <Row>
                                <Col md={12}>
                                  <label>{t('diagnosispage.start_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.start_date}
                                    selectsStart
                                    id="date_pick"
                                    className="date_pick form-control"
                                    placeholderText="From"
                                    name="start_dates"
                                    value={this.state.start_date} 
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleStart}
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col md={12}>
                                <label>{t('diagnosispage.end_date')}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                  <DatePicker
                                    selected={this.state.end_date}
                                    selectsEnd
                                    id="date_pick"
                                    className="form-control"
                                    name="end_dates"
                                    value={this.state.end_date}
                                    startDate={this.state.start_date}
                                    endDate={this.state.end_date}
                                    onChange={this.handleEnd}
                                  />
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="modal-footer">
                        <button
                            type="button"
                            onClick={this.resetpage}
                            className="btn btn-warning waves-effect"
                            data-dismiss="modal"
                          >
                            {t('diagnosispage.reset_filter')}
                          </button>
                          <button
                            type="button"
                            onClick={this.tog_standard}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                          >
                            {t('diagnosispage.cancel')}
                          </button>
                          <button
                            type="button"
                            onClick={this.getFilteredDateData}
                            className="btn filter_btn waves-effect waves-light"
                          >
                            {t('diagnosispage.proceed')}
                          </button>
                        </div>
                      </Modal>
        <div className="container-fluid homepageFirstRow">
        <Card>
          <CardBody>                  
          <Row>
                         
            <Col xl="12">
            <Card>
                <CardBody>
                
                <Row>
                <div className="operations_div text-center">
                <h4 className="operations_div text-center">Test AC Manual Operations</h4>
                </div>
                <PaginationProvider
                    pagination={
                        paginationFactory(options)
                    }
                    >
                    { contentTable }
                  </PaginationProvider>
                </Row>
                </CardBody>
            </Card>
            </Col>
          </Row>
          </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(AcOperations);
