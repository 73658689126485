import React from 'react';
import '../../assets/scss/custom.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
// import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
//import moment from 'moment';
import {MetaTags} from 'react-meta-tags';
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button
} from "reactstrap";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

const { ExportCSVButton } = CSVExport;

const selectRow = {
    mode: 'checkbox',
    clickToSelect: true
  };

  const roles = {
    'Administrator': 'Administrator',
    'AC Operator': 'AC Operator',
    'Assistance': 'Assistance',
    'Diagnosis Developer': 'Diagnosis Developer',
    'Diagnosis Tester': 'Diagnosis Tester',
    'Diagnosis Manager': 'Diagnosis Manager',
    'Commercial': 'Commercial'
};

const socselectOptions = {
    'TM': 'Tecnomotor'
};
  
class Users extends React.Component {
    constructor(props) {
        super(props);
        //this.state = {};
        this.state = {
          //value: localStorage.getItem('lang'),
          users: [],
          success_msg: false,
          confirm_msg: false,
          success_dlg: false,
          error_dlg: false,
          dynamic_title: "",
          dynamic_description: "",
          confirm_both: false,
          delete_user_id: '',
          img_html: false,
          loading:true,
          logged_user:'',
          date: new Date()
        };
        i18next.changeLanguage(localStorage.getItem('lang'));
        this.openDeleteDialog = this.openDeleteDialog.bind(this);
      }
      

  componentDidMount() {

    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);
    this.setState({
      logged_user:user_id,
    })

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
     
      if(findresponses.users === 0){
        window.history.back();
      }
    })

    fetch(config.API+'users?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse)
      this.setState({
        users:findresponse,
        loading:false
      })
      
    })
  }
  add_user() {
    window.location.replace('add_user');
  }

  openDeleteDialog(id) {
    var user_id = id.target.value;
    this.setState({ delete_user_id: user_id, confirm_both: true })
  }

  deleteUser() {
    var user_id = this.state.delete_user_id;
    fetch(config.API+'delete_user/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     if (findresponse.status === "success") {
      this.setState({ success_msg: true })       
    }else {
      this.setState({ img_html: true })
    }
    })
  }

  delete_success(){
    window.location.reload(false);
  }

  render() {
   
    const { t } = this.props;
    const columns = [
      // {
      //     text: t('userspage.user_id'),
      //     dataField: "id",
      //     classes: "selcheck",
      //     filter: textFilter(),
      //   },
        
        {
          text: t('userspage.firstname'),
          dataField: "firstname",
          filter: textFilter(),
        },
        {
          text: t('userspage.lastname'),
          dataField: "lastname",
          filter: textFilter(),
        },
        {
          text: t('userspage.role'),
          dataField: "role",
          filter: selectFilter({
            options: roles
        })
        },
        {
          
          text: t('userspage.email'),
          dataField: "email",
          filter: textFilter(),
        },
        {
          text: t('userspage.address'),
          dataField: "address",
          filter: textFilter(),
        },
        {
          text: t('userspage.soc'),
          dataField: "soc",
          filter: selectFilter({
            options: socselectOptions
        })
        }
        ,
        // {
        //     text: t('userspage.ling'),
        //     dataField: "ling"
        //   }
        //   ,
          {
            text: t('userspage.edit'),
            dataField: "id",
            formatter: (rowContent, row) => {
                //const sn_no = row.id;
                const id = row.id;
                return (    
                <Link to={"/edit_user?id="+id}><button className='btn btn-outline-primary btn lg'>{t('userspage.view')}</button></Link>
                )
              }
          },
          {
            text: t('userspage.delete'),
            dataField: "id",
            formatter: (rowContent, row) => {
              if(row.id === this.state.logged_user){
                return (    
                  <Button
                  color="warning"
                  value={row.id}
                  id="sa-params"
                >
                  <i className="ion ion-md-close-circle-outline"></i> {t('userspage.not_allow')}
                </Button>
                )
              }else{
                return (    
                  <Button
                  color="danger"
                  value={row.id}
                  id="sa-params"
                  onClick={this.openDeleteDialog}
                >
                  <i className="ion ion-md-close-circle-outline"></i>
                </Button>
                )
              }
              }
          }
  ];
    const options = {
      //custom: true,
      paginationSize: 6,
      pageStartIndex: 1,
      firstPageText: t('userspage.firstPageText'),
      prePageText: t('userspage.prePageText'),
      nextPageText: t('userspage.nextPageText'),
      lastPageText: t('userspage.lastPageText'),
      nextPageTitle: t('userspage.nextPageTitle'),
      prePageTitle: t('userspage.prePageTitle'),
      firstPageTitle: t('userspage.firstPageTitle'),
      lastPageTitle: t('userspage.lastPageTitle'),
      showTotal: true,
      totalSize: this.state.users.length,
      sizePerPageList: [
        {
          text: '10', value: 10
        }, 
        {
          text: '50', value: 50
        },{
          text: '100', value: 100
        }
      ]
    };
    
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div className="container-fluid">
          {this.state.confirm_both ? (
                  <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() =>
                      this.deleteUser()
                    }
                    onCancel={() =>
                      this.setState({
                        confirm_both: false,
                        error_dlg: true,
                        dynamic_title: "Cancelled",
                        dynamic_description: "User is Not Removed"
                      })
                    }
                  >
                    You won't be able to revert this!
                  </SweetAlert>
                ) : null}
                {this.state.success_msg ? (
                    <SweetAlert
                      title="Good job!"
                      success
                      showCancel
                      confirmBtnBsStyle="success"
                      onConfirm={() => this.delete_success()}
                    >
                      User Deleted Successfully
                    </SweetAlert>
                  ) : null}
                  {this.state.img_html ? (
                    <SweetAlert
                      title={
                        <span>
                          Failed!
                        </span>
                      }
                      warning
                      onConfirm={() => this.setState({ img_html: false })}
                    >
                      Failed to Delete User
                    </SweetAlert>
                  ) : null}
          <ToolkitProvider
            keyField="id"
            data={ this.state.users }
            columns={ columns }
            exportCSV={ {  exportAll: false , onlyExportSelection: true, fileName: 'Users Data '+this.state.date+'.csv',} }
            >
            {
                props => (
                <div>
                    <ul className="export_btns">
                    <li><button
                          type="button"
                          onClick={this.add_user}
                          className="btn filter_btn waves-effect waves-light"
                        >
                          <i className="ti-plus"> {t('userspage.add_user')}</i>
                        </button></li>
                      <li>
                      <Dropdown
                        isOpen={this.state.btnprimary1}
                        direction="left"
                        toggle={() =>
                          this.setState({ btnprimary1: !this.state.btnprimary1 })
                        }
                      >
                      <DropdownToggle tag="button" className="btn btn-success">
                      {t('userspage.export')}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem><ExportCSVButton { ...props.csvProps }>{t('userspage.exp_sel')}</ExportCSVButton></DropdownItem>
                        <DropdownItem><CSVLink className="react-bs-table-csv-btn btn btn-default" filename={"Users Data "+this.state.date+".csv"} data={this.state.users}>{t('userspage.exp_all')}</CSVLink></DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                      </li>
                        
                        {/* <li><ExportCSVButton { ...props.csvProps }>{t('versionpage.exp_sel')}</ExportCSVButton></li>
                        <li><CSVLink className="react-bs-table-csv-btn btn btn-default" data={this.state.products}>{t('versionpage.exp_all')}</CSVLink></li> */}
                    </ul>
                        <br/>
                        <br/>
                        <hr/>
                    <BootstrapTable
                    striped
                    hover
                    { ...props.baseProps }
                    selectRow={ selectRow }
                    filter={ filterFactory() }
                    { ...paginationTableProps }
                    pagination={ paginationFactory(options) }
                    wrapperClasses="table-responsive"
                    />
                </div>
                )
            }
            </ToolkitProvider>
      </div>
    );

    return (
      
      <Card>
          <CardBody>
          <MetaTags>
      <title>Users | Technomotor Diagnostic Web Portal</title>
      <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" />
      </MetaTags>
      <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
          {/* <br/>
         <br/> */}
          <h4 className="text-center">{t('userspage.user_rec')}</h4>
                    
        <PaginationProvider
          pagination={
            paginationFactory(options)
          }
        >
          { contentTable }
        </PaginationProvider>
        <br/>
        <br/>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation()(Users);