import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
//import { Link } from "react-router-dom";
// import BarApexChart from "../AllCharts/apex/barchart";
// import StackedBarChart from "../AllCharts/apex/stackedbarchart";
import { Bar } from 'react-chartjs-2';
import { Progress } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
// import Vector from "../Maps/Vectormap";
import { VectorMap } from "react-jvectormap";
import '../../assets/scss/custom.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import {MetaTags} from 'react-meta-tags';
import "chartist/dist/scss/chartist.scss";
import {Sugar} from 'react-preloaders';
import config from './../../helpers/Constants';

const progressBar = {
  height: '5px'
}
const countryName = {
  float:'left'
}
const countryCount = {
  float:'right'
}

const countryLine = {
  display: 'flow-root',
  marginBottom: '0px'
}

class ACDashboard extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem('lang'),
      auto_ac_user_count:0,
      auto_count:0,
      ver_ac_user_count:0,
      ver_count:0,
      auto_brand_list:[],
      auto_brand_item:[],
      auto_model_list:[],
      auto_model_item:[],
      auto_lang_list:[],
      autolang_item:[],
      auto_location_count:[],
      ver_location_count:[],
      auto_tester_list:[],
      auto_tester_item:[],
      ver_tester_list:[],
      ver_tester_item:[],
      ver_lang_list:[],
      ver_lang_item:[],
      ver_ac_password:0,
      ver_exp_password:0,
      ver_tester_undefined:0,
      ver_tester_android:0,
      ver_tester_windows:0,
      ver_tester_embedded:0,
      ver_tester_ap_undefined:0,
      ver_tester_ap_android:0,
      ver_tester_ap_windows:0,
      ver_tester_ap_embedded:0,
      ver_tester_total:0,
      ver_tester_ap_total:0,
      daysvalue: localStorage.getItem('data_visible_days'),
      auto_au_daysvalue: '',
      auto_daysvalue: '',
      auto_brands_daysvalue: '',
      auto_models_daysvalue: '',
      auto_lang_daysvalue: '',
      auto_map_daysvalue: '',
      auto_tester_daysvalue: '',
      ver_au_daysvalue: '',
      ver_daysvalue: '',
      ver_map_daysvalue: '',
      ver_tester_daysvalue: '',
      ver_tester_d_daysvalue: '',
      ver_tester_ap_daysvalue: '',
      ver_ap_daysvalue: '',
      ver_lang_daysvaule: '',
      man_ac_user_count:0,
      man_count:0,
      man_lang_list:[],
      man_lang_item:[],
      man_location_count:[],
      man_tester_list:[],
      man_tester_item:[],
      man_au_daysvalue: '',
      man_daysvalue: '',
      man_brands_daysvalue: '',
      man_models_daysvalue: '',
      man_lang_daysvalue: '',
      man_map_daysvalue: '',
      man_tester_daysvalue: '',
      ser_ac_user_count:0,
      ser_count:0,
      ser_lang_list:[],
      ser_lang_item:[],
      ser_location_count:[],
      ser_tester_list:[],
      ser_tester_item:[],
      ser_au_daysvalue: '',
      ser_daysvalue: '',
      ser_brands_daysvalue: '',
      ser_models_daysvalue: '',
      ser_lang_daysvalue: '',
      ser_map_daysvalue: '',
      ser_tester_daysvalue: '',
      loading:true,

    };
    i18next.changeLanguage(localStorage.getItem('lang'));
    this.handleAutoAcUserBarChange = this.handleAutoAcUserBarChange.bind(this);
    this.handleAutoBarChange = this.handleAutoBarChange.bind(this);
    this.handleAutoBrandBarChange = this.handleAutoBrandBarChange.bind(this);
    this.handleAutoModelBarChange = this.handleAutoModelBarChange.bind(this);
    this.handleAutoLangBarChange = this.handleAutoLangBarChange.bind(this);
    this.handleAutoLocationBarChange = this.handleAutoLocationBarChange.bind(this);
    this.handleAutoTesterBarChange = this.handleAutoTesterBarChange.bind(this);
    this.handleVerAcUserBarChange = this.handleVerAcUserBarChange.bind(this);
    this.handleVerBarChange = this.handleVerBarChange.bind(this);
    this.handleVerLangBarChange = this.handleVerLangBarChange.bind(this);
    this.handleVerApBarChange = this.handleVerApBarChange.bind(this);
    this.handleVerLocationBarChange = this.handleVerLocationBarChange.bind(this);
    this.handleVerTesterBarChange = this.handleVerTesterBarChange.bind(this);
    this.handleVerTesterDisBarChange = this.handleVerTesterDisBarChange.bind(this);
    this.handleVerTesterDisApBarChange = this.handleVerTesterDisApBarChange.bind(this);
    this.handleManAcUserBarChange = this.handleManAcUserBarChange.bind(this);
    this.handleManBarChange = this.handleManBarChange.bind(this);
    this.handleManLangBarChange = this.handleManLangBarChange.bind(this);
    this.handleManLocationBarChange = this.handleManLocationBarChange.bind(this);
    this.handleManTesterBarChange = this.handleManTesterBarChange.bind(this);
    this.handleSerAcUserBarChange = this.handleSerAcUserBarChange.bind(this);
    this.handleSerBarChange = this.handleSerBarChange.bind(this);
    this.handleSerLangBarChange = this.handleSerLangBarChange.bind(this);
    this.handleSerLocationBarChange = this.handleSerLocationBarChange.bind(this);
    this.handleSerTesterBarChange = this.handleSerTesterBarChange.bind(this);
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    var data_visible_days = localStorage.getItem('data_visible_days');
    this.setState({
      auto_au_daysvalue:data_visible_days,
      auto_daysvalue: data_visible_days,
      auto_brands_daysvalue: data_visible_days,
      auto_map_daysvalue: data_visible_days,
      auto_tester_daysvalue: data_visible_days,
      ver_au_daysvalue: data_visible_days,
      ver_daysvalue: data_visible_days,
      ver_map_daysvalue: data_visible_days,
      ver_tester_daysvalue: data_visible_days,
      ver_tester_d_daysvalue: data_visible_days,
      ver_tester_ap_daysvalue: data_visible_days,
      ver_ap_daysvalue: data_visible_days,
      ver_lang_daysvaule: data_visible_days,
      auto_models_daysvalue: data_visible_days,
      auto_lang_daysvalue: data_visible_days,
      man_au_daysvalue:data_visible_days,
      man_daysvalue: data_visible_days,
      man_map_daysvalue: data_visible_days,
      man_tester_daysvalue: data_visible_days,
      man_lang_daysvalue: data_visible_days,
      ser_au_daysvalue:data_visible_days,
      ser_daysvalue: data_visible_days,
      ser_map_daysvalue: data_visible_days,
      ser_tester_daysvalue: data_visible_days,
      ser_lang_daysvalue: data_visible_days
    })

    if(!data_visible_days){
      var day = "14";
    }else{
      day = data_visible_days;
    }

    fetch(config.API+'get_user_permissions/'+user_id, {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Responses)=>Responses.json()).then((findresponses)=>{
      //console.log(findresponses);
      this.setState({
        loading:false
      })
      if(findresponses.users === 0){
        window.history.back();
      }
    })

// Auto Operations
    //auto active users
    fetch(config.API+'ac_dashboard_auto_ops_active_users/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_ac_user_count:findresponse.ver_count
      })
    })

    //auto count
    fetch(config.API+'ac_dashboard_auto_ops/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        auto_count:findresponse.ver_count
      })
    })

    //auto brands
    fetch(config.API+'ac_dashboard_auto_ops_brand/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_brand_list:findresponse.brands.b_list,
        auto_brand_item:findresponse.brands.b_items
      })
    })

    //auto Models
    fetch(config.API+'ac_dashboard_auto_ops_model/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_model_list:findresponse.models.b_list,
        auto_model_item:findresponse.models.b_items
      })
    })

    //auto Lang
    fetch(config.API+'ac_dashboard_auto_ops_lang/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_lang_list:findresponse.languages.b_list,
        auto_lang_item:findresponse.languages.b_items
      })
    })

    //auto location count
    fetch(config.API+'ac_dashboard_auto_ops_location/'+day+'?company=TM', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse);
        this.setState({
          auto_location_count:findresponse.italy_count
        })
      })

    //auto Tester
    fetch(config.API+'ac_dashboard_auto_ops_tester/'+day+'?company=TM', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
       // console.log(findresponse);
        this.setState({
          auto_tester_list:findresponse.testers.t_list,
          auto_tester_item:findresponse.testers.t_items,
        })
      })

// Man Operations
    //man active users
    fetch(config.API+'ac_dashboard_man_ops_active_users/'+day+'?company=TM', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse);
        this.setState({
          man_ac_user_count:findresponse.ver_count
        })
      })
  
      //man count
      fetch(config.API+'ac_dashboard_man_ops/'+day+'?company=TM', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
       // console.log(findresponse);
        this.setState({
          man_count:findresponse.ver_count
        })
      })

      //man Lang
      fetch(config.API+'ac_dashboard_man_ops_lang/'+day+'?company=TM', {
        headers: {  
          'Nexion_API_Key': config.API_KEY
        }
      }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse);
        this.setState({
          man_lang_list:findresponse.languages.b_list,
          man_lang_item:findresponse.languages.b_items
        })
      })
  
      //man location count
      fetch(config.API+'ac_dashboard_man_ops_location/'+day+'?company=TM', {
          headers: {  
            'Nexion_API_Key': config.API_KEY
          }
        }).then((Response)=>Response.json()).then((findresponse)=>{
          //console.log(findresponse);
          this.setState({
            man_location_count:findresponse.italy_count
          })
        })
  
      //man Tester
      fetch(config.API+'ac_dashboard_man_ops_tester/'+day+'?company=TM', {
          headers: {  
            'Nexion_API_Key': config.API_KEY
          }
        }).then((Response)=>Response.json()).then((findresponse)=>{
         // console.log(findresponse);
          this.setState({
            man_tester_list:findresponse.testers.t_list,
            man_tester_item:findresponse.testers.t_items,
          })
        })

// Ser Operations
    //ser active users
    fetch(config.API+'ac_dashboard_services_active_users/'+day+'?company=TM', {
    headers: {  
        'Nexion_API_Key': config.API_KEY
    }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    //console.log(findresponse);
    this.setState({
        ser_ac_user_count:findresponse.ver_count
    })
    })

    //ser count
    fetch(config.API+'ac_dashboard_services/'+day+'?company=TM', {
    headers: {  
        'Nexion_API_Key': config.API_KEY
    }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    // console.log(findresponse);
    this.setState({
        ser_count:findresponse.ver_count
    })
    })

    //ser Lang
    fetch(config.API+'ac_dashboard_services_lang/'+day+'?company=TM', {
    headers: {  
        'Nexion_API_Key': config.API_KEY
    }
    }).then((Response)=>Response.json()).then((findresponse)=>{
    //console.log(findresponse);
    this.setState({
        ser_lang_list:findresponse.languages.b_list,
        ser_lang_item:findresponse.languages.b_items
    })
    })

    //ser location count
    fetch(config.API+'ac_dashboard_services_location/'+day+'?company=TM', {
        headers: {  
        'Nexion_API_Key': config.API_KEY
        }
    }).then((Response)=>Response.json()).then((findresponse)=>{
        //console.log(findresponse);
        this.setState({
        ser_location_count:findresponse.italy_count
        })
    })

    //ser Tester
    fetch(config.API+'ac_dashboard_services_tester/'+day+'?company=TM', {
        headers: {  
        'Nexion_API_Key': config.API_KEY
        }
    }).then((Response)=>Response.json()).then((findresponse)=>{
        // console.log(findresponse);
        this.setState({
        ser_tester_list:findresponse.testers.t_list,
        ser_tester_item:findresponse.testers.t_items,
        })
    })

//Ac Version
    //Version Lang
    fetch(config.API+'ac_dashboard_ver_lang/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_lang_list:findresponse.languages.b_list,
        ver_lang_item:findresponse.languages.b_items
      })
    })

    //Version Active Password
    fetch(config.API+'ac_dashboard_ver_ap/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_ac_password:findresponse.ac_password,
        ver_exp_password:findresponse.exp_password
      })
    })

    //version location count
    fetch(config.API+'ac_dashboard_ver_location/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_location_count:findresponse.italy_count
      })
    })

    //version count
    fetch(config.API+'ac_dashboard_verions/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_count:findresponse.ver_count
      })
    })

    //version ac user count
    fetch(config.API+'ac_dashboard_ver_active_users/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_ac_user_count:findresponse.ver_count
      })
    })

    //Version Tester
    fetch(config.API+'ac_dashboard_ver_tester/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_tester_list:findresponse.testers.t_list,
        ver_tester_item:findresponse.testers.t_items,
      })
    })

    //Version Tester distribution
    fetch(config.API+'ac_dashboard_ver_tester_dis/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     //console.log(findresponse[2].total);
      
        if (findresponse[0].total) {
          this.setState({
            ver_tester_undefined:findresponse[0].total
          })
        }else{
          this.setState({
            ver_tester_undefined:0,
          })
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_android:findresponse[1].total
          })
        }else{
          this.setState({
            ver_tester_android:0,
          })
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_windows:findresponse[2].total
          })
        }else{
          this.setState({
            ver_tester_windows:0,
          })
        }

        if (findresponse[3].total) {
          this.setState({
            ver_tester_embedded:findresponse[3].total
          })
        }else{
          this.setState({
            ver_tester_embedded:0,
          })
        }
        this.setState({
          ver_tester_total:findresponse[0].total+findresponse[1].total+findresponse[2].total+findresponse[3].total
        })
    })

    //Version Tester distribution Active Password
    fetch(config.API+'ac_dashboard_ver_tester_dis_ap/'+day+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     //console.log(findresponse[1].total);
      
        if (findresponse[0].total) {
          this.setState({
            ver_tester_ap_undefined:findresponse[0].total
          })
        }else{
          this.setState({
            ver_tester_ap_undefined:0,
          })
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_ap_android:findresponse[1].total
          })
        }else{
          this.setState({
            ver_tester_ap_android:0,
          })
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_ap_windows:findresponse[2].total
          })
        }else{
          this.setState({
            ver_tester_ap_windows:0,
          })
        }

        if (findresponse[3].total) {
          this.setState({
            ver_tester_ap_embedded:findresponse[3].total
          })
        }else{
          this.setState({
            ver_tester_ap_embedded:0,
          })
        }

        this.setState({
          ver_tester_ap_total:findresponse[0].total+findresponse[1].total+findresponse[2].total+findresponse[3].total
        })
    })
}

// AC Auto Handler

  handleAutoAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_active_users/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      this.setState({
        auto_ac_user_count:findresponse.ver_count,
        auto_au_daysvalue:days
      })
    })
  }

  handleAutoBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        auto_count:findresponse.ver_count,
        auto_daysvalue: days
      })
    })
  }

  handleAutoBrandBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_brand/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_brand_list:findresponse.brands.b_list,
        auto_brand_item:findresponse.brands.b_items,
        auto_brands_daysvalue: days
      })
    })
  }

  handleAutoModelBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_model/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_model_list:findresponse.models.b_list,
        auto_model_item:findresponse.models.b_items,
        auto_models_daysvalue: days
      })
    })
  }

  handleAutoLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_lang/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_lang_list:findresponse.languages.b_list,
        auto_lang_item:findresponse.languages.b_items,
        auto_lang_daysvalue: days
      })
    })
  }

  handleAutoLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_location/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        auto_location_count:findresponse.italy_count,
        auto_map_daysvalue: days
      })
    })
  }

  handleAutoTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_auto_ops_tester/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        auto_tester_list:findresponse.testers.t_list,
        auto_tester_item:findresponse.testers.t_items,
        auto_tester_daysvalue: days
      })
    })
  }

  // AC Man Handler

  handleManAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_man_ops_active_users/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      this.setState({
        man_ac_user_count:findresponse.ver_count,
        man_au_daysvalue:days
      })
    })
  }

  handleManBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_man_ops/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        man_count:findresponse.ver_count,
        man_daysvalue: days
      })
    })
  }

  handleManLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_man_ops_lang/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        man_lang_list:findresponse.languages.b_list,
        man_lang_item:findresponse.languages.b_items,
        man_lang_daysvalue: days
      })
    })
  }

  handleManLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_man_ops_location/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        man_location_count:findresponse.italy_count,
        man_map_daysvalue: days
      })
    })
  }

  handleManTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_man_ops_tester/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        man_tester_list:findresponse.testers.t_list,
        man_tester_item:findresponse.testers.t_items,
        man_tester_daysvalue: days
      })
    })
  }

  // AC Services Handler

  handleSerAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_services_active_users/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      this.setState({
        ser_ac_user_count:findresponse.ver_count,
        ser_au_daysvalue:days
      })
    })
  }

  handleSerBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_services/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ser_count:findresponse.ver_count,
        ser_daysvalue: days
      })
    })
  }

  handleSerLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_services_lang/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ser_lang_list:findresponse.languages.b_list,
        ser_lang_item:findresponse.languages.b_items,
        ser_lang_daysvalue: days
      })
    })
  }

  handleSerLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_services_location/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ser_location_count:findresponse.italy_count,
        ser_map_daysvalue: days
      })
    })
  }

  handleSerTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_services_tester/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ser_tester_list:findresponse.testers.t_list,
        ser_tester_item:findresponse.testers.t_items,
        ser_tester_daysvalue: days
      })
    })
  }

//AC Version Handler
  handleVerLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_lang/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_lang_list:findresponse.languages.b_list,
        ver_lang_item:findresponse.languages.b_items,
        ver_lang_daysvalue: days
      })
    })
  }

  handleVerApBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_ap/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_ac_password:findresponse.ac_password,
        ver_exp_password:findresponse.exp_password,
        ver_ap_daysvalue: days
      })
    })
  }

  handleVerAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_active_users/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_ac_user_count:findresponse.ver_count,
        ver_au_daysvalue: days
      })
    })
  }

  handleVerBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_verions/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_count:findresponse.ver_count,
        ver_daysvalue: days
      })
    })
  }

  handleVerLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_location/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
      //console.log(findresponse);
      this.setState({
        ver_location_count:findresponse.italy_count,
        ver_map_daysvalue: days
      })
    })
  }

  handleVerTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_tester/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     // console.log(findresponse);
      this.setState({
        ver_tester_list:findresponse.testers.t_list,
        ver_tester_item:findresponse.testers.t_items,
        ver_tester_daysvalue: days
     
      })
    })
  }

  handleVerTesterDisBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_tester_dis/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     //console.log(findresponse[2].total);
     this.setState({
      ver_tester_d_daysvalue: days
     })
      
        if (findresponse[0].total) {
          this.setState({
            ver_tester_undefined:findresponse[0].total
          })
        }else{
          this.setState({
            ver_tester_undefined:0,
          })
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_android:findresponse[1].total
          })
        }else{
          this.setState({
            ver_tester_android:0,
          })
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_windows:findresponse[2].total
          })
        }else{
          this.setState({
            ver_tester_windows:0,
          })
        }
        this.setState({
          ver_tester_total:findresponse[0].total+findresponse[1].total+findresponse[2].total
        })
    })
  }

  handleVerTesterDisApBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API+'ac_dashboard_ver_tester_dis_ap/'+days+'?company=TM', {
      headers: {  
        'Nexion_API_Key': config.API_KEY
      }
    }).then((Response)=>Response.json()).then((findresponse)=>{
     //console.log(findresponse[1].total);

     this.setState({
      ver_tester_ap_daysvalue: days
     })
      
        if (findresponse[0].total) {
          this.setState({
            ver_tester_ap_undefined:findresponse[0].total
          })
        }else{
          this.setState({
            ver_tester_ap_undefined:0,
          })
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_ap_android:findresponse[1].total
          })
        }else{
          this.setState({
            ver_tester_ap_android:0,
          })
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_ap_windows:findresponse[2].total
          })
        }else{
          this.setState({
            ver_tester_ap_windows:0,
          })
        }

        this.setState({
          ver_tester_ap_total:findresponse[0].total+findresponse[1].total+findresponse[2].total
        })
    })
  }


  render() {
    const { t } = this.props;
    const data = {
      labels: this.state.auto_brand_list,
      datasets: [
          {
              label: "Brand",
              backgroundColor: "#ec4561",
              borderColor: "#ec4561",
              borderWidth: 1,
              hoverBackgroundColor: "#ec4561",
              hoverBorderColor: "#ec4561",
              data: this.state.auto_brand_item
          }
      ]

  };

  const option = {
    tootlbar: {
        show: false
    },
    tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return currentValue;
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    const model_data = {
      labels: this.state.auto_model_list,
      datasets: [
          {
              label: "Models",
              backgroundColor: "#ffd200",
              borderColor: "#ffd200",
              borderWidth: 1,
              hoverBackgroundColor: "#ffd200",
              hoverBorderColor: "#ffd200",
              data: this.state.auto_model_item
          }
      ]

  };

  const model_option = {
    tootlbar: {
        show: false
    },
    tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return currentValue;
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    const lang_data = {
      labels: this.state.auto_lang_list,
      datasets: [
          {
              label: "Languages",
              backgroundColor: "#c3c852",
              borderColor: "#c3c852",
              borderWidth: 1,
              hoverBackgroundColor: "#c3c852",
              hoverBorderColor: "#c3c852",
              data: this.state.auto_lang_item
          }
      ]

  };

  const lang_option = {
    tootlbar: {
        show: false
    },
    tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return currentValue;
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    const man_lang_data = {
        labels: this.state.man_lang_list,
        datasets: [
            {
                label: "Languages",
                backgroundColor: "#c3c852",
                borderColor: "#c3c852",
                borderWidth: 1,
                hoverBackgroundColor: "#c3c852",
                hoverBorderColor: "#c3c852",
                data: this.state.man_lang_item
            }
        ]
  
    };
  
    const man_lang_option = {
      tootlbar: {
          show: false
      },
      tooltips: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var currentValue = dataset.data[tooltipItem.index];
                      return currentValue;
                  },
                  title: function (tooltipItem, data) {
                      return data.labels[tooltipItem[0].index];
                  }
              }
          }
      }

      const ser_lang_data = {
        labels: this.state.ser_lang_list,
        datasets: [
            {
                label: "Languages",
                backgroundColor: "#c3c852",
                borderColor: "#c3c852",
                borderWidth: 1,
                hoverBackgroundColor: "#c3c852",
                hoverBorderColor: "#c3c852",
                data: this.state.ser_lang_item
            }
        ]
  
    };
  
    const ser_lang_option = {
      tootlbar: {
          show: false
      },
      tooltips: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      var dataset = data.datasets[tooltipItem.datasetIndex];
                      var currentValue = dataset.data[tooltipItem.index];
                      return currentValue;
                  },
                  title: function (tooltipItem, data) {
                      return data.labels[tooltipItem[0].index];
                  }
              }
          }
      }

    const ver_lang_data = {
      labels: this.state.ver_lang_list,
      datasets: [
          {
              label: "Languages",
              backgroundColor: "#ec4561",
              borderColor: "#ec4561",
              borderWidth: 1,
              hoverBackgroundColor: "#ec4561",
              hoverBorderColor: "#ec4561",
              data: this.state.ver_lang_item
          }
      ]

  };

  const ver_lang_option = {
    tootlbar: {
        show: false
    },
    tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var currentValue = dataset.data[tooltipItem.index];
                    return currentValue;
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    }

    const donut_options = {
      colors: ['#4EAABE', '#C3C852'],
      labels: this.state.auto_tester_list,
      dataLabels: {
          enabled: true
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            }
          }
        }
      },
      legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
      },
      responsive: [{
          breakpoint: 480,
          options: {
              chart: {
                  width: 200,
              },
              legend: {
                  position: 'bottom',
                  horizontalAlign: 'center',
              }
          }
      }],
  series: this.state.auto_tester_item
}

const man_donut_options = {
    colors: ['#4EAABE', '#C3C852'],
    labels: this.state.man_tester_list,
    dataLabels: {
        enabled: true
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          }
        }
      }
    },
    legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
            }
        }
    }],
series: this.state.man_tester_item
}

const ser_donut_options = {
    colors: ['#4EAABE', '#C3C852'],
    labels: this.state.ser_tester_list,
    dataLabels: {
        enabled: true
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          }
        }
      }
    },
    legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 200,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
            }
        }
    }],
series: this.state.ser_tester_item
}

const ver_donut_options = {
  colors: ['#4EAABE', '#C3C852'],
  labels: [this.state.ver_tester_list],
  dataLabels: {
      enabled: true
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
        }
      }
    }
  },
  legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
  },
  responsive: [{
      breakpoint: 480,
      options: {
          chart: {
              width: 200,
          },
          legend: {
              position: 'bottom',
              horizontalAlign: 'center',
          }
      }
  }],
series: this.state.ver_tester_item
}

const ver_ap_options = {
  colors: ['#4EAABE', '#C3C852'],
  labels: ['Active Password', 'Expired Password'],
  dataLabels: {
      enabled: true
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
        }
      }
    }
  },
  legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
  },
  responsive: [{
      breakpoint: 480,
      options: {
          chart: {
              width: 200,
          },
          legend: {
              position: 'bottom',
              horizontalAlign: 'center',
          }
      }
  }],
series: [this.state.ver_ac_password, this.state.ver_exp_password]
}

 const tester_type_options = {
  colors: ['#C3C852', '#4EAABE', '#013363', '#C3C852'],
  chart: {
      stacked: true,
      toolbar: {
          show: false,
      },
  },
  dataLabels: {
      enabled: false,
  },
  plotOptions: {
      bar: {
          columnWidth: '20%',
      },
  },
  grid: {
      borderColor: '#f8f8fa',
      row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
      },
  },

  xaxis: {
      categories: ['Tecnomotor AC20 (Total - '+this.state.ver_tester_total+')'],
      labels: {
          formatter: function (val) {
              return val
          },
      },
      axisBorder: {
          show: false
      },
      axisTicks: {
          show: false
      }
  },
  yaxis: {
      title: {
          text: undefined
      },
  },
  tooltip: {
      y: {
          formatter: function (val) {
              return val
          }
      }
  },
  fill: {
      opacity: 1
  },

  legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
  },
series: [{
  name: 'Undefined',
  data: [this.state.ver_tester_undefined]
  },{
  name: 'Windows',
  data: [this.state.ver_tester_windows]
  },{
    name: 'Android',
    data: [this.state.ver_tester_android]
  },{
    name: 'Embedded',
    data: [0, this.state.ver_tester_embedded]
  }]
}

const ap_tester_type_options = {
  colors: ['#C3C852', '#4EAABE', '#013363', '#C3C852'],
  chart: {
      stacked: true,
      toolbar: {
          show: false,
      },
  },
  dataLabels: {
      enabled: false,
  },
  plotOptions: {
      bar: {
          columnWidth: '20%',
      },
  },
  grid: {
      borderColor: '#f8f8fa',
      row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
      },
  },

  xaxis: {
      categories: ['Tecnomotor AC20 (Total - '+this.state.ver_tester_ap_total+')'],
      labels: {
          formatter: function (val) {
              return val
          },
      },
      axisBorder: {
          show: false
      },
      axisTicks: {
          show: false
      }
  },
  yaxis: {
      title: {
          text: undefined
      },
  },
  tooltip: {
      y: {
          formatter: function (val) {
              return val
          }
      }
  },
  fill: {
      opacity: 1
  },

  legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
  },
series: [{
  name: 'Undefined',
  data: [this.state.ver_tester_ap_undefined]
  },{
  name: 'Windows',
  data: [this.state.ver_tester_ap_windows]
  },{
    name: 'Android',
    data: [this.state.ver_tester_ap_android]
  },{
    name: 'Embedded',
    data: [0, this.state.ver_tester_ap_embedded]
  }]
}
    return (
      <React.Fragment>
        <MetaTags>
        <title>AC Dashboard | Tecnomotor Web Portal</title> 
        <meta name="description" content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments" /> </MetaTags> 
        <Sugar customLoading={this.state.loading} background="#ec4561" color={'#ffffff'} />
        <div className="container-fluid">
          
          
          <h5 className="stats_name">AC VERSION</h5>
          <Row className="homepageFirstRow">

            <Col xl={3}>
              <Card className="dash_card_ver">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-ios-person text-tm"></i>  Active Users</h4>
                <select className="form-control" onChange={this.handleVerAcUserBarChange} value={this.state.ver_au_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                <div className="text-center py-4">
                <br></br>
                    <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                <br></br>
                    <h1 className="text-center text-tm">{this.state.ver_ac_user_count}</h1>
                    <p className="text-tm font-size-14 pt-1">{t('homepage.users')}</p>
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3}>
            <Card className="dash_card_ver">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-list text-tm"></i> {t('homepage.version')}</h4>
                    <select className="form-control" onChange={this.handleVerBarChange} value={this.state.ver_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                    <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-ios-list text-tm text-center dashIcon"></i>
                <br></br>
                        <h1 className="text-center text-tm">{this.state.ver_count}</h1>
                        <p className="text-tm font-size-14 pt-1">{t('homepage.version')}</p>
                    </div>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
            
            </Col>
          </Row>
          <Row>
          <Col xl={6}>
          <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i>Active Password</h4>
                  <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerApBarChange} value={this.state.ver_ap_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <br/><br/><br/>
            <React.Fragment>
                <ReactApexChart options={ver_ap_options} series={ver_ap_options.series} type="donut" height="285" />
            </React.Fragment>
            <br/>
            </CardBody>
            </Card>
            </Col>
            
            <Col xl={6}>
            <Card className="dash_card_ver">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Languages</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerLangBarChange} value={this.state.ver_lang_daysvaule}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={ver_lang_data} options={ver_lang_option} />
                </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
            <Card className="dash_card_ver">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-md-analytics text-tm"></i> {t('homepage.countries')}</h4>
                <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerLocationBarChange} value={this.state.ver_map_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                <Row>
                  <Col xl={4}>
                    <br></br>
                  <p className="card-title-desc">
                    <b>{this.state.ver_count} - {t('homepage.all_tests')}</b>
                  </p>

                  <div className="">
                    <div className="mb-10">
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>{this.state.ver_location_count}</span></p>
                    <Progress style={progressBar} color="success" value={this.state.ver_location_count}></Progress>
                    </div>
                      <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>0</span></p>
                      <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>China</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                  </div>
                  </Col>
                  <Col xl={8}>
                  <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                          width: "100%",
                          height: "100%"
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                          },
                          hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer"
                          },
                          selected: {
                            fill: "#002C77" //what colour clicked country will be
                          },
                          selectedHover: {}
                        }}
                        series= {{
                          regions: [{
                              values: {
                                EU:'#ec4561'
                              }
                          }]
                      }}
                        containerClassName="map"
                      />
                  
                  </Col>
                </Row>
                </CardBody>
            </Card>
            </Col>

            <Col xl={6}>
            <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type</h4>
                  <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerTesterBarChange} value={this.state.ver_tester_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <br/><br/><br/>
            <React.Fragment>
                <ReactApexChart options={ver_donut_options} series={ver_donut_options.series} type="donut" height="285" />
            </React.Fragment>
            <br/>
            </CardBody>
            </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
                  <Card className="dash_card_ver">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type distribution (Overall)</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerTesterDisBarChange} value={this.state.ver_tester_d_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <React.Fragment>
                <ReactApexChart options={tester_type_options} series={tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
                  <Card className="dash_card_ver">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type distribution (With Active Password)</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleVerTesterDisApBarChange} value={this.state.ver_tester_ap_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <React.Fragment>
                <ReactApexChart options={ap_tester_type_options} series={ap_tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row>
          <h5 className="stats_name">AC AUTO OPERATIONS</h5>
          <Row className="homepageFirstRow">

            <Col xl={3}>
              <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-ios-person text-tm"></i>  Active Users</h4>
                <select className="form-control" onChange={this.handleAutoAcUserBarChange} value={this.state.auto_au_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                {/* <p>OVERALL</p> */}
                
                <div className="text-center py-4">
                <br></br>
                <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                <br></br>
    <h1 className="text-center text-tm">{this.state.auto_ac_user_count}</h1>
                    <p className="text-tm font-size-14 pt-1">{t('homepage.users')}</p>
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3}>
              <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-md-medkit text-tm"></i> AC Auto Operations</h4>
                    <select className="form-control" onChange={this.handleAutoBarChange} value={this.state.auto_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                  
                    <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-md-medkit text-tm text-center dashIcon"></i>
                <br></br>
                        <h1 className="text-center text-tm">{this.state.auto_count}</h1>
                        <p className="text-tm font-size-14 pt-1">OPERATIONS</p>
                    </div>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
            
            </Col>
          </Row>

          <Row>
          <Col xl={6}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Brands</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleAutoBrandBarChange} value={this.state.auto_brands_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={data} options={option} />
                </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
            
            <Col xl={6}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Models</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleAutoModelBarChange} value={this.state.auto_models_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={model_data} options={model_option} />
                </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row>

          <Row>
          <Col xl={6}>
          <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-md-analytics text-tm"></i> {t('homepage.countries')}</h4>
                <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleAutoLocationBarChange} value={this.state.auto_map_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                <Row>
                  <Col xl={4}>
                    <br></br>
                  <p className="card-title-desc">
                    <b>{this.state.auto_count} - {t('homepage.all_tests')}</b>
                  </p>

                  <div className="">
                    <div className="mb-10">
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>{this.state.auto_location_count}</span></p>
                    <Progress style={progressBar} color="success" value={this.state.auto_location_count}></Progress>
                    </div>
                      <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>0</span></p>
                      <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>China</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                  </div>
                  </Col>
                  <Col xl={8}>
                  <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                          width: "100%",
                          height: "100%"
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                          },
                          hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer"
                          },
                          selected: {
                            fill: "#002C77" //what colour clicked country will be
                          },
                          selectedHover: {}
                        }}
                        series= {{
                          regions: [{
                              values: {
                                EU:'#ec4561'
                              }
                          }]
                      }}
                        containerClassName="map"
                      />
                  
                  </Col>
                </Row>
                </CardBody>
            </Card>
            </Col>
            
            <Col xl={6}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Languages</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleAutoLangBarChange} value={this.state.auto_lang_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={lang_data} options={lang_option} />
                </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row>

          <Row>
            
            <Col xl={6}>
            <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type</h4>
                  <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleAutoTesterBarChange} value={this.state.auto_tester_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <br/><br/><br/>
            <React.Fragment>
                <ReactApexChart options={donut_options} series={donut_options.series} type="donut" height="285" />
            </React.Fragment>
            <br/>
            </CardBody>
            </Card>
            </Col>
            <Col xl={6}>
            
            </Col>

          </Row>

          <h5 className="stats_name">AC Manual OPERATIONS</h5><Row className="homepageFirstRow">

            <Col xl={3}>
            <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-ios-person text-tm"></i>  Active Users</h4>
                <select className="form-control" onChange={this.handleManAcUserBarChange} value={this.state.man_au_daysvalue}>
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                </select>
                {/* <p>OVERALL</p> */}
                
                <div className="text-center py-4">
                <br></br>
                <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                <br></br>
            <h1 className="text-center text-tm">{this.state.man_ac_user_count}</h1>
                    <p className="text-tm font-size-14 pt-1">{t('homepage.users')}</p>
                </div>
                </CardBody>
            </Card>
            </Col>
            <Col xl={3}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-md-medkit text-tm"></i> AC Manual Operations</h4>
                    <select className="form-control" onChange={this.handleManBarChange} value={this.state.man_daysvalue}>
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                </select>
                
                    <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-md-medkit text-tm text-center dashIcon"></i>
                <br></br>
                        <h1 className="text-center text-tm">{this.state.man_count}</h1>
                        <p className="text-tm font-size-14 pt-1">OPERATIONS</p>
                    </div>
                    </CardBody>
                </Card>
            </Col>
            <Col xl={6}>

            </Col>
            </Row>
            <Row>
            <Col xl={6}>
            <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-md-analytics text-tm"></i> {t('homepage.countries')}</h4>
                <Row>
                    <Col xl={3}>
                    <select className="form-control" onChange={this.handleManLocationBarChange} value={this.state.man_map_daysvalue}>
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                </select>
                    </Col>
                    <Col xl={9}>
                        
                    </Col>
                    </Row>
                <Row>
                <Col xl={4}>
                    <br></br>
                <p className="card-title-desc">
                    <b>{this.state.auto_count} - {t('homepage.all_tests')}</b>
                </p>

                <div className="">
                    <div className="mb-10">
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>{this.state.man_location_count}</span></p>
                    <Progress style={progressBar} color="success" value={this.state.man_location_count}></Progress>
                    </div>
                    <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>China</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                </div>
                </Col>
                <Col xl={8}>
                <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                        width: "100%",
                        height: "100%"
                        }}
                        regionStyle={{
                        initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                        },
                        hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer"
                        },
                        selected: {
                            fill: "#002C77" //what colour clicked country will be
                        },
                        selectedHover: {}
                        }}
                        series= {{
                        regions: [{
                            values: {
                                EU:'#ec4561'
                            }
                        }]
                    }}
                        containerClassName="map"
                    />
                
                </Col>
                </Row>
                </CardBody>
            </Card>
            </Col>

            <Col xl={6}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Languages</h4>
                    <Row>
                    <Col xl={3}>
                    <select className="form-control" onChange={this.handleManLangBarChange} value={this.state.man_lang_daysvalue}>
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                </select>
                    </Col>
                    <Col xl={9}>
                        
                    </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={man_lang_data} options={man_lang_option} />
                </React.Fragment>
                    </CardBody>
                </Card>
            </Col>
            </Row>

            <Row>

            <Col xl={6}>
            <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type</h4>
                <Row>
                    <Col xl={3}>
                    <select className="form-control" onChange={this.handleManTesterBarChange} value={this.state.man_tester_daysvalue}>
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                </select>
                    </Col>
                    <Col xl={9}>
                        
                    </Col>
                    </Row>
                    <br/><br/><br/>
            <React.Fragment>
                <ReactApexChart options={man_donut_options} series={man_donut_options.series} type="donut" height="285" />
            </React.Fragment>
            <br/>
            </CardBody>
            </Card>
            </Col>
            <Col xl={6}>
            </Col>
          </Row>
          
          <h5 className="stats_name">AC SERVICES</h5>
          <Row className="homepageFirstRow">

            <Col xl={3}>
              <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-ios-person text-tm"></i>  Active Users</h4>
                <select className="form-control" onChange={this.handleSerAcUserBarChange} value={this.state.ser_au_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                {/* <p>OVERALL</p> */}
                
                <div className="text-center py-4">
                <br></br>
                <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                <br></br>
    <h1 className="text-center text-tm">{this.state.ser_ac_user_count}</h1>
                    <p className="text-tm font-size-14 pt-1">{t('homepage.users')}</p>
                </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3}>
              <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-md-medkit text-tm"></i>AC SERVICES</h4>
                    <select className="form-control" onChange={this.handleSerBarChange} value={this.state.ser_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                  
                    <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-md-medkit text-tm text-center dashIcon"></i>
                <br></br>
                        <h1 className="text-center text-tm">{this.state.ser_count}</h1>
                        <p className="text-tm font-size-14 pt-1">SERVICES</p>
                    </div>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
            
            </Col>
          </Row>
          <Row>
          <Col xl={6}>
          <Card className="dash_card">
                <CardBody>
                <h4 className="card-title"><i className="ion ion-md-analytics text-tm"></i> {t('homepage.countries')}</h4>
                <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleSerLocationBarChange} value={this.state.ser_map_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                <Row>
                  <Col xl={4}>
                    <br></br>
                  <p className="card-title-desc">
                    <b>{this.state.ser_count} - {t('homepage.all_tests')}</b>
                  </p>

                  <div className="">
                    <div className="mb-10">
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Italy</span><span style={countryCount}>{this.state.ser_location_count}</span></p>
                    <Progress style={progressBar} color="success" value={this.state.ser_location_count}></Progress>
                    </div>
                      <p style={countryLine}><span style={countryName}>India</span><span style={countryCount}>0</span></p>
                      <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Brazil</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>United States</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Russia</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>France</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>Spain</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    <div className="mb-10">
                    <p style={countryLine}><span style={countryName}>China</span><span style={countryCount}>0</span></p>
                    <Progress style={progressBar} color="success" value={0}></Progress>
                    </div>
                    
                  </div>
                  </Col>
                  <Col xl={8}>
                  <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                          width: "100%",
                          height: "100%"
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                          },
                          hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer"
                          },
                          selected: {
                            fill: "#002C77" //what colour clicked country will be
                          },
                          selectedHover: {}
                        }}
                        series= {{
                          regions: [{
                              values: {
                                EU:'#ec4561'
                              }
                          }]
                      }}
                        containerClassName="map"
                      />
                  
                  </Col>
                </Row>
                </CardBody>
            </Card>
            </Col>
            
            <Col xl={6}>
            <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-car text-tm"></i> Languages</h4>
                    <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleSerLangBarChange} value={this.state.ser_lang_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    
                    <React.Fragment>
                    <Bar width={300} height={138} data={ser_lang_data} options={ser_lang_option} />
                </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row>

          <Row>
            
            <Col xl={6}>
            <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title"><i className="ion ion-ios-speedometer text-tm"></i> Tester Type</h4>
                  <Row>
                      <Col xl={3}>
                      <select className="form-control" onChange={this.handleSerTesterBarChange} value={this.state.ser_tester_daysvalue}>
                      <option value="0">OVERALL</option>
                      <option value="14">14 Days</option>
                      <option value="30">30 Days</option>
                      <option value="90">90 Days</option>
                      <option value="180">180 Days</option>
                      <option value="365">365 Days</option>
                  </select>
                      </Col>
                      <Col xl={9}>
                        
                      </Col>
                    </Row>
                    <br/><br/><br/>
            <React.Fragment>
                <ReactApexChart options={ser_donut_options} series={ser_donut_options.series} type="donut" height="285" />
            </React.Fragment>
            <br/>
            </CardBody>
            </Card>
            </Col>
            <Col xl={6}>
            
            </Col>

          </Row>

          {/* <Row>
            <Col xl={6}>
                  <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-nexion"></i> Tester Type distribution (Overall)</h4>
                    
                    <React.Fragment>
                <ReactApexChart options={tester_type_options} series={tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
                  <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-nexion"></i> Tester Type distribution (With Active Password)</h4>
                    
                    <React.Fragment>
                <ReactApexChart options={tester_type_options} series={tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row> */}
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ACDashboard);
