import React, { Component } from "react";
//import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Card, CardBody } from "reactstrap";
//import { Link } from "react-router-dom";
// import BarApexChart from "../AllCharts/apex/barchart";
// import StackedBarChart from "../AllCharts/apex/stackedbarchart";
import { Bar } from "react-chartjs-2";
import { Progress } from "reactstrap";
import ReactApexChart from "react-apexcharts";
// import Vector from "../Maps/Vectormap";
import { VectorMap } from "react-jvectormap";
import "../../assets/scss/custom.scss";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { MetaTags } from "react-meta-tags";
import "chartist/dist/scss/chartist.scss";
import { Sugar } from "react-preloaders";
import config from "./../../helpers/Constants";

const progressBar = {
  height: "5px",
};
const countryName = {
  float: "left",
};
const countryCount = {
  float: "right",
};

const countryLine = {
  display: "flow-root",
  marginBottom: "0px",
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.state = {
      value: localStorage.getItem("lang"),
      diag_ac_user_count: 0,
      diagnosis_count: 0,
      ver_ac_user_count: 0,
      ver_count: 0,
      diag_brand_list: [],
      diag_brand_item: [],
      diag_model_list: [],
      diag_model_item: [],
      diag_ecu_list: [],
      diag_ecu_item: [],
      diag_lang_list: [],
      diag_lang_item: [],
      diag_location_count: [],
      ver_location_count: [],
      diag_tester_list: [],
      diag_tester_item: [],
      ver_tester_list: [],
      ver_tester_item: [],
      ver_lang_list: [],
      ver_lang_item: [],
      ver_ac_password: 0,
      ver_exp_password: 0,
      ver_tester_undefined: 0,
      ver_tester_android: 0,
      ver_tester_windows: 0,
      ver_tester_ap_undefined: 0,
      ver_tester_ap_android: 0,
      ver_tester_ap_windows: 0,
      ver_tester_total: 0,
      ver_tester_ap_total: 0,
      daysvalue: localStorage.getItem("data_visible_days"),
      diag_au_daysvalue: "",
      diag_daysvalue: "",
      diag_brands_daysvalue: "",
      diag_models_daysvalue: "",
      diag_lang_daysvalue: "",
      diag_ecu_daysvalue: "",
      diag_map_daysvalue: "",
      diag_tester_daysvalue: "",
      ver_au_daysvalue: "",
      ver_daysvalue: "",
      ver_map_daysvalue: "",
      ver_tester_daysvalue: "",
      ver_tester_d_daysvalue: "",
      ver_tester_ap_daysvalue: "",
      ver_ap_daysvalue: "",
      ver_lang_daysvaule: "",
      loading: true,
    };
    i18next.changeLanguage(localStorage.getItem("lang"));
    this.handleDiagAcUserBarChange = this.handleDiagAcUserBarChange.bind(this);
    this.handleDiagBarChange = this.handleDiagBarChange.bind(this);
    this.handleDiagBrandBarChange = this.handleDiagBrandBarChange.bind(this);
    this.handleDiagModelBarChange = this.handleDiagModelBarChange.bind(this);
    this.handleDiagEcuBarChange = this.handleDiagEcuBarChange.bind(this);
    this.handleDiagLangBarChange = this.handleDiagLangBarChange.bind(this);
    this.handleDiagLocationBarChange =
      this.handleDiagLocationBarChange.bind(this);
    this.handleDiagTesterBarChange = this.handleDiagTesterBarChange.bind(this);
    this.handleVerAcUserBarChange = this.handleVerAcUserBarChange.bind(this);
    this.handleVerBarChange = this.handleVerBarChange.bind(this);
    this.handleVerLangBarChange = this.handleVerLangBarChange.bind(this);
    this.handleVerApBarChange = this.handleVerApBarChange.bind(this);
    this.handleVerLocationBarChange =
      this.handleVerLocationBarChange.bind(this);
    this.handleVerTesterBarChange = this.handleVerTesterBarChange.bind(this);
    this.handleVerTesterDisBarChange =
      this.handleVerTesterDisBarChange.bind(this);
    this.handleVerTesterDisApBarChange =
      this.handleVerTesterDisApBarChange.bind(this);
  }

  componentDidMount() {
    let decodedString = localStorage.getItem("authUser");
    let authUser = JSON.parse(atob(decodedString));
    var user_id = authUser.id;
    //console.log(user_id);
    var data_visible_days = localStorage.getItem("data_visible_days");
    this.setState({
      diag_au_daysvalue: data_visible_days,
      diag_daysvalue: data_visible_days,
      diag_brands_daysvalue: data_visible_days,
      diag_map_daysvalue: data_visible_days,
      diag_tester_daysvalue: data_visible_days,
      ver_au_daysvalue: data_visible_days,
      ver_daysvalue: data_visible_days,
      ver_map_daysvalue: data_visible_days,
      ver_tester_daysvalue: data_visible_days,
      ver_tester_d_daysvalue: data_visible_days,
      ver_tester_ap_daysvalue: data_visible_days,
      ver_ap_daysvalue: data_visible_days,
      ver_lang_daysvaule: data_visible_days,
      diag_models_daysvalue: data_visible_days,
      diag_lang_daysvalue: data_visible_days,
      diag_ecu_daysvalue: data_visible_days,
    });

    if (!data_visible_days) {
      var day = "14";
    } else {
      day = data_visible_days;
    }

    fetch(config.API + "get_user_permissions/" + user_id, {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Responses) => Responses.json())
      .then((findresponses) => {
        //console.log(findresponses);
        this.setState({
          loading: false,
        });
        if (findresponses.users === 0) {
          window.history.back();
        }
      });

    //diag active users
    fetch(config.API + "get_dashboard_active_users/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_ac_user_count: findresponse.diag_count,
        });
      });

    //diagnosis count
    fetch(config.API + "get_dashboard_diagnosis/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_count: findresponse.diag_count,
        });
      });

    //diagnosis brands
    fetch(
      config.API + "get_dashboard_diagnosis_brands/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_brand_list: findresponse.brands.b_list,
          diag_brand_item: findresponse.brands.b_items,
        });
      });

    //diagnosis Models
    fetch(
      config.API + "get_dashboard_diagnosis_models/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_model_list: findresponse.models.b_list,
          diag_model_item: findresponse.models.b_items,
        });
      });

    //diagnosis Ecus
    fetch(config.API + "get_dashboard_diagnosis_ecu/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_ecu_list: findresponse.models.b_list,
          diag_ecu_item: findresponse.models.b_items,
        });
      });

    //diagnosis Lang
    fetch(config.API + "get_dashboard_diagnosis_lang/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_lang_list: findresponse.languages.b_list,
          diag_lang_item: findresponse.languages.b_items,
        });
      });

    //Version Lang
    fetch(config.API + "get_dashboard_version_lang/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_lang_list: findresponse.languages.b_list,
          ver_lang_item: findresponse.languages.b_items,
        });
      });

    //Version Active Password
    fetch(config.API + "get_dashboard_version_ap/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_ac_password: findresponse.ac_password,
          ver_exp_password: findresponse.exp_password,
        });
      });

    //diagnosis location count
    fetch(
      config.API + "get_dashboard_diagnosis_location/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_location_count: findresponse.italy_count,
        });
      });

    //version location count
    fetch(
      config.API + "get_dashboard_version_location/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_location_count: findresponse.italy_count,
        });
      });

    //version count
    fetch(config.API + "get_dashboard_versions/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_count: findresponse.ver_count,
        });
      });

    //version ac user count
    fetch(
      config.API + "get_dashboard_version_active_users/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_ac_user_count: findresponse.ver_count,
        });
      });

    //Diagnosis Tester
    fetch(
      config.API + "get_dashboard_diagnosis_tester/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_tester_list: findresponse.testers.t_list,
          diag_tester_item: findresponse.testers.t_items,
        });
      });

    //Version Tester
    fetch(config.API + "get_dashboard_version_tester/" + day + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_tester_list: findresponse.testers.t_list,
          ver_tester_item: findresponse.testers.t_items,
        });
      });

    //Version Tester distribution
    fetch(
      config.API + "get_dashboard_version_tester_os/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[2].total);

        if (findresponse[0].total) {
          this.setState({
            ver_tester_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_windows: 0,
          });
        }
        this.setState({
          ver_tester_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });

    //Version Tester distribution Active Password
    fetch(
      config.API + "get_dashboard_version_tester_os_ap/" + day + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[1].total);

        if (findresponse[0].total) {
          this.setState({
            ver_tester_ap_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_ap_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_ap_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_ap_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_ap_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_ap_windows: 0,
          });
        }

        this.setState({
          ver_tester_ap_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });
  }

  handleDiagAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_active_users/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        this.setState({
          diag_ac_user_count: findresponse.diag_count,
          diag_au_daysvalue: days,
        });
      });
  }

  handleDiagBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_diagnosis/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_count: findresponse.diag_count,
          diag_daysvalue: days,
        });
      });
  }

  handleDiagBrandBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_diagnosis_brands/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_brand_list: findresponse.brands.b_list,
          diag_brand_item: findresponse.brands.b_items,
          diag_brands_daysvalue: days,
        });
      });
  }

  handleDiagModelBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_diagnosis_models/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_model_list: findresponse.models.b_list,
          diag_model_item: findresponse.models.b_items,
          diag_models_daysvalue: days,
        });
      });
  }

  handleDiagEcuBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_diagnosis_ecu/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_ecu_list: findresponse.models.b_list,
          diag_ecu_item: findresponse.models.b_items,
          diag_ecu_daysvalue: days,
        });
      });
  }

  handleDiagLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_diagnosis_lang/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_lang_list: findresponse.languages.b_list,
          diag_lang_item: findresponse.languages.b_items,
          diag_lang_daysvalue: days,
        });
      });
  }

  handleVerLangBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_version_lang/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_lang_list: findresponse.languages.b_list,
          ver_lang_item: findresponse.languages.b_items,
          ver_lang_daysvalue: days,
        });
      });
  }

  handleVerApBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_version_ap/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_ac_password: findresponse.ac_password,
          ver_exp_password: findresponse.exp_password,
          ver_ap_daysvalue: days,
        });
      });
  }

  handleDiagLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_diagnosis_location/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          diag_location_count: findresponse.italy_count,
          diag_map_daysvalue: days,
        });
      });
  }

  handleDiagTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_diagnosis_tester/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          diag_tester_list: findresponse.testers.t_list,
          diag_tester_item: findresponse.testers.t_items,
          diag_tester_daysvalue: days,
        });
      });
  }

  handleVerAcUserBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_version_active_users/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_ac_user_count: findresponse.ver_count,
          ver_au_daysvalue: days,
        });
      });
  }

  handleVerBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_versions/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_count: findresponse.ver_count,
          ver_daysvalue: days,
        });
      });
  }

  handleVerLocationBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_version_location/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse);
        this.setState({
          ver_location_count: findresponse.italy_count,
          ver_map_daysvalue: days,
        });
      });
  }

  handleVerTesterBarChange(prod) {
    var days = prod.target.value;
    fetch(config.API + "get_dashboard_version_tester/" + days + "?company=TM", {
      headers: {
        Nexion_API_Key: config.API_KEY,
      },
    })
      .then((Response) => Response.json())
      .then((findresponse) => {
        // console.log(findresponse);
        this.setState({
          ver_tester_list: findresponse.testers.t_list,
          ver_tester_item: findresponse.testers.t_items,
          ver_tester_daysvalue: days,
        });
      });
  }

  handleVerTesterDisBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_version_tester_os/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[2].total);
        this.setState({
          ver_tester_d_daysvalue: days,
        });

        if (findresponse[0].total) {
          this.setState({
            ver_tester_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_windows: 0,
          });
        }
        this.setState({
          ver_tester_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });
  }

  handleVerTesterDisApBarChange(prod) {
    var days = prod.target.value;
    fetch(
      config.API + "get_dashboard_version_tester_os_ap/" + days + "?company=TM",
      {
        headers: {
          Nexion_API_Key: config.API_KEY,
        },
      }
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        //console.log(findresponse[1].total);

        this.setState({
          ver_tester_ap_daysvalue: days,
        });

        if (findresponse[0].total) {
          this.setState({
            ver_tester_ap_undefined: findresponse[0].total,
          });
        } else {
          this.setState({
            ver_tester_ap_undefined: 0,
          });
        }

        if (findresponse[1].total) {
          this.setState({
            ver_tester_ap_android: findresponse[1].total,
          });
        } else {
          this.setState({
            ver_tester_ap_android: 0,
          });
        }

        if (findresponse[2].total) {
          this.setState({
            ver_tester_ap_windows: findresponse[2].total,
          });
        } else {
          this.setState({
            ver_tester_ap_windows: 0,
          });
        }

        this.setState({
          ver_tester_ap_total:
            findresponse[0].total +
            findresponse[1].total +
            findresponse[2].total,
        });
      });
  }

  render() {
    const { t } = this.props;
    const data = {
      labels: this.state.diag_brand_list,
      datasets: [
        {
          label: "Brand",
          backgroundColor: "#ec4561",
          borderColor: "#ec4561",
          borderWidth: 1,
          hoverBackgroundColor: "#ec4561",
          hoverBorderColor: "#ec4561",
          data: this.state.diag_brand_item,
        },
      ],
    };

    const option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const model_data = {
      labels: this.state.diag_model_list,
      datasets: [
        {
          label: "Models",
          backgroundColor: "#ec4561",
          borderColor: "#ec4561",
          borderWidth: 1,
          hoverBackgroundColor: "#ec4561",
          hoverBorderColor: "#ec4561",
          data: this.state.diag_model_item,
        },
      ],
    };

    const model_option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const lang_data = {
      labels: this.state.diag_lang_list,
      datasets: [
        {
          label: "Languages",
          backgroundColor: "#c3c852",
          borderColor: "#c3c852",
          borderWidth: 1,
          hoverBackgroundColor: "#c3c852",
          hoverBorderColor: "#c3c852",
          data: this.state.diag_lang_item,
        },
      ],
    };

    const lang_option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const ver_lang_data = {
      labels: this.state.ver_lang_list,
      datasets: [
        {
          label: "Languages",
          backgroundColor: "#ec4561",
          borderColor: "#ec4561",
          borderWidth: 1,
          hoverBackgroundColor: "#ec4561",
          hoverBorderColor: "#ec4561",
          data: this.state.ver_lang_item,
        },
      ],
    };

    const ver_lang_option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const ecu_data = {
      labels: this.state.diag_ecu_list,
      datasets: [
        {
          label: "ECUs",
          backgroundColor: "#ec4561",
          borderColor: "#ec4561",
          borderWidth: 1,
          hoverBackgroundColor: "#ec4561",
          hoverBorderColor: "#ec4561",
          data: this.state.diag_ecu_item,
        },
      ],
    };

    const ecu_option = {
      tootlbar: {
        show: false,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var dataset = data.datasets[tooltipItem.datasetIndex];
            var currentValue = dataset.data[tooltipItem.index];
            return currentValue;
          },
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
        },
      },
    };

    const donut_options = {
      colors: ["#4EAABE", "#C3C852"],
      labels: this.state.diag_tester_list,
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: this.state.diag_tester_item,
    };

    const ver_donut_options = {
      colors: ["#4EAABE", "#C3C852"],
      labels: this.state.ver_tester_list,
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: this.state.ver_tester_item,
    };

    const ver_ap_options = {
      colors: ["#4EAABE", "#C3C852"],
      labels: [t("homepage.active_password"), t("homepage.expired_password")],
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "center",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              horizontalAlign: "center",
            },
          },
        },
      ],
      series: [this.state.ver_ac_password, this.state.ver_exp_password],
    };

    const tester_type_options = {
      colors: ["#C3C852", "#4EAABE", "#013363"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories: [
          "Tecnomotor iONE Cruiser (Total - " +
            this.state.ver_tester_total +
            ")",
        ],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      series: [
        {
          name: "Undefined",
          data: [this.state.ver_tester_undefined],
        },
        {
          name: "Windows",
          data: [this.state.ver_tester_windows],
        },
        {
          name: "Android",
          data: [this.state.ver_tester_android],
        },
      ],
    };

    const ap_tester_type_options = {
      colors: ["#C3C852", "#4EAABE", "#013363"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "20%",
        },
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories: [
          "Tecnomotor iONE Cruiser  (Total - " +
            this.state.ver_tester_ap_total +
            ")",
        ],
        labels: {
          formatter: function (val) {
            return val;
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
      series: [
        {
          name: "Undefined",
          data: [this.state.ver_tester_ap_undefined],
        },
        {
          name: "Windows",
          data: [this.state.ver_tester_ap_windows],
        },
        {
          name: "Android",
          data: [this.state.ver_tester_ap_android],
        },
      ],
    };
    return (
      <React.Fragment>
        <MetaTags>
          <title>Dashboard | Technomotor Web Portal</title>{" "}
          <meta
            name="description"
            content="Diagnostic Portal For Technomotor and Magneti Marelli Instruments"
          />{" "}
        </MetaTags>
        <Sugar
          customLoading={this.state.loading}
          background="#ec4561"
          color={"#ffffff"}
        />
        <div className="container-fluid">
          <h5 className="stats_name">DIAGNOSIS</h5>
          <Row className="homepageFirstRow">
            <Col xl={3}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-person text-tm"></i>{" "}
                    {t("homepage.active_users")}
                  </h4>
                  <select
                    className="form-control"
                    onChange={this.handleDiagAcUserBarChange}
                    value={this.state.diag_au_daysvalue}
                  >
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                  </select>
                  {/* <p>OVERALL</p> */}

                  <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                    <br></br>
                    <h1 className="text-center text-tm">
                      {this.state.diag_ac_user_count}
                    </h1>
                    <p className="text-tm font-size-14 pt-1">
                      {t("homepage.users")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-md-medkit text-tm"></i>{" "}
                    {t("homepage.diagnosis")}
                  </h4>
                  <select
                    className="form-control"
                    onChange={this.handleDiagBarChange}
                    value={this.state.diag_daysvalue}
                  >
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                  </select>

                  <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-md-medkit text-tm text-center dashIcon"></i>
                    <br></br>
                    <h1 className="text-center text-tm">
                      {this.state.diag_count}
                    </h1>
                    <p className="text-tm font-size-14 pt-1">
                      {t("homepage.diagnosis")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}></Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-car text-tm"></i>{" "}
                    {t("homepage.brands")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagBrandBarChange}
                        value={this.state.diag_brands_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>

                  <React.Fragment>
                    <Bar
                      width={300}
                      height={138}
                      data={data}
                      options={option}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-car text-tm"></i>{" "}
                    {t("homepage.models")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagModelBarChange}
                        value={this.state.diag_models_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>

                  <React.Fragment>
                    <Bar
                      width={300}
                      height={138}
                      data={model_data}
                      options={model_option}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-car text-tm"></i> ECUs
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagEcuBarChange}
                        value={this.state.diag_ecu_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>

                  <React.Fragment>
                    <Bar
                      width={300}
                      height={138}
                      data={ecu_data}
                      options={ecu_option}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-car text-tm"></i>{" "}
                    {t("homepage.languages")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagLangBarChange}
                        value={this.state.diag_lang_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>

                  <React.Fragment>
                    <Bar
                      width={300}
                      height={138}
                      data={lang_data}
                      options={lang_option}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-md-analytics text-tm"></i>{" "}
                    {t("homepage.countries")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagLocationBarChange}
                        value={this.state.diag_map_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <Row>
                    <Col xl={4}>
                      <br></br>
                      <p className="card-title-desc">
                        <b>
                          {this.state.diag_count} - {t("homepage.all_tests")}
                        </b>
                      </p>

                      <div className="">
                        <div className="mb-10">
                          <div className="mb-10">
                            <p style={countryLine}>
                              <span style={countryName}>Italy</span>
                              <span style={countryCount}>
                                {this.state.diag_location_count}
                              </span>
                            </p>
                            <Progress
                              style={progressBar}
                              color="success"
                              value={this.state.diag_location_count}
                            ></Progress>
                          </div>
                          <p style={countryLine}>
                            <span style={countryName}>India</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Brazil</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>United States</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Russia</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>

                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>France</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Spain</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>China</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                      </div>
                    </Col>
                    <Col xl={8}>
                      <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                          width: "100%",
                          height: "100%",
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0,
                          },
                          hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer",
                          },
                          selected: {
                            fill: "#ec4561", //what colour clicked country will be
                          },
                          selectedHover: {},
                        }}
                        series={{
                          regions: [
                            {
                              values: {
                                EU: "#ec4561",
                              },
                            },
                          ],
                        }}
                        containerClassName="map"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card className="dash_card">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-speedometer text-tm"></i>{" "}
                    {t("homepage.tester_type")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleDiagTesterBarChange}
                        value={this.state.diag_tester_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      options={donut_options}
                      series={donut_options.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col xl={6}>
                  <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-nexion"></i> Tester Type distribution (Overall)</h4>
                    
                    <React.Fragment>
                <ReactApexChart options={tester_type_options} series={tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
            <Col xl={6}>
                  <Card className="dash_card">
                    <CardBody>
                    <h4 className="card-title"><i className="ion ion-ios-speedometer text-nexion"></i> Tester Type distribution (With Active Password)</h4>
                    
                    <React.Fragment>
                <ReactApexChart options={tester_type_options} series={tester_type_options.series} type="bar" height="290" />
            </React.Fragment>
                    </CardBody>
                  </Card>
            </Col>
          </Row> */}

          <h5 className="stats_name">VERSION</h5>
          <Row className="homepageFirstRow">
            <Col xl={3}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-person text-tm"></i>{" "}
                    {t("homepage.active_users")}
                  </h4>
                  <select
                    className="form-control"
                    onChange={this.handleVerAcUserBarChange}
                    value={this.state.ver_au_daysvalue}
                  >
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                  </select>
                  <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-ios-person text-tm text-center dashIcon"></i>
                    <br></br>
                    <h1 className="text-center text-tm">
                      {this.state.ver_ac_user_count}
                    </h1>
                    <p className="text-tm font-size-14 pt-1">
                      {t("homepage.users")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-list text-tm"></i>{" "}
                    {t("homepage.version")}
                  </h4>
                  <select
                    className="form-control"
                    onChange={this.handleVerBarChange}
                    value={this.state.ver_daysvalue}
                  >
                    <option value="0">OVERALL</option>
                    <option value="14">14 Days</option>
                    <option value="30">30 Days</option>
                    <option value="90">90 Days</option>
                    <option value="180">180 Days</option>
                    <option value="365">365 Days</option>
                  </select>
                  <div className="text-center py-4">
                    <br></br>
                    <i class="ion ion-ios-list text-tm text-center dashIcon"></i>
                    <br></br>
                    <h1 className="text-center text-tm">
                      {this.state.ver_count}
                    </h1>
                    <p className="text-tm font-size-14 pt-1">
                      {t("homepage.version")}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}></Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-speedometer text-tm"></i>{" "}
                    {t("homepage.active_password")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerApBarChange}
                        value={this.state.ver_ap_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      options={ver_ap_options}
                      series={ver_ap_options.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-car text-tm"></i>{" "}
                    {t("homepage.languages")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerLangBarChange}
                        value={this.state.ver_lang_daysvaule}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>

                  <React.Fragment>
                    <Bar
                      width={300}
                      height={138}
                      data={ver_lang_data}
                      options={ver_lang_option}
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-md-analytics text-tm"></i>{" "}
                    {t("homepage.countries")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerLocationBarChange}
                        value={this.state.ver_map_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <Row>
                    <Col xl={4}>
                      <br></br>
                      <p className="card-title-desc">
                        <b>
                          {this.state.ver_count} - {t("homepage.all_tests")}
                        </b>
                      </p>

                      <div className="">
                        <div className="mb-10">
                          <div className="mb-10">
                            <p style={countryLine}>
                              <span style={countryName}>Italy</span>
                              <span style={countryCount}>
                                {this.state.ver_location_count}
                              </span>
                            </p>
                            <Progress
                              style={progressBar}
                              color="success"
                              value={this.state.ver_location_count}
                            ></Progress>
                          </div>
                          <p style={countryLine}>
                            <span style={countryName}>India</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Brazil</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>United States</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Russia</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>

                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>France</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>Spain</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                        <div className="mb-10">
                          <p style={countryLine}>
                            <span style={countryName}>China</span>
                            <span style={countryCount}>0</span>
                          </p>
                          <Progress
                            style={progressBar}
                            color="success"
                            value={0}
                          ></Progress>
                        </div>
                      </div>
                    </Col>
                    <Col xl={8}>
                      <VectorMap
                        map={"continents_mill"}
                        // onRegionClick={this.handleClick} // gets the country code
                        backgroundColor="transparent"
                        ref="map"
                        containerStyle={{
                          width: "100%",
                          height: "100%",
                        }}
                        regionStyle={{
                          initial: {
                            fill: "#e0e0e0",
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0,
                          },
                          hover: {
                            "fill-opacity": 0.8,
                            cursor: "pointer",
                          },
                          selected: {
                            fill: "#ec4561", //what colour clicked country will be
                          },
                          selectedHover: {},
                        }}
                        series={{
                          regions: [
                            {
                              values: {
                                EU: "#ec4561",
                              },
                            },
                          ],
                        }}
                        containerClassName="map"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-speedometer text-tm"></i>{" "}
                    {t("homepage.tester_type")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerTesterBarChange}
                        value={this.state.ver_tester_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <React.Fragment>
                    <ReactApexChart
                      options={ver_donut_options}
                      series={ver_donut_options.series}
                      type="donut"
                      height="285"
                    />
                  </React.Fragment>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-speedometer text-tm"></i>{" "}
                    {t("homepage.tester_type_distribution_overall")}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerTesterDisBarChange}
                        value={this.state.ver_tester_d_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <React.Fragment>
                    <ReactApexChart
                      options={tester_type_options}
                      series={tester_type_options.series}
                      type="bar"
                      height="290"
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card className="dash_card_ver">
                <CardBody>
                  <h4 className="card-title">
                    <i className="ion ion-ios-speedometer text-tm"></i>{" "}
                    {t(
                      "homepage.tester_type_distribution_with_active_password"
                    )}
                  </h4>
                  <Row>
                    <Col xl={3}>
                      <select
                        className="form-control"
                        onChange={this.handleVerTesterDisApBarChange}
                        value={this.state.ver_tester_ap_daysvalue}
                      >
                        <option value="0">OVERALL</option>
                        <option value="14">14 Days</option>
                        <option value="30">30 Days</option>
                        <option value="90">90 Days</option>
                        <option value="180">180 Days</option>
                        <option value="365">365 Days</option>
                      </select>
                    </Col>
                    <Col xl={9}></Col>
                  </Row>
                  <React.Fragment>
                    <ReactApexChart
                      options={ap_tester_type_options}
                      series={ap_tester_type_options.series}
                      type="bar"
                      height="290"
                    />
                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Dashboard);
